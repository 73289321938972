import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all terms
export const termsFetchStart = () => {
  return {
    type: actionTypes.FETCH_TERMS_START,
  };
};
export const termsFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_TERMS_SUCCESS,
    terms: data.data,
  };
};
export const termsCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_TERMS_COUNT_SUCCESS,
    count: data,
  };
};
export const termsFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_TERMS_FAIL,
    error: error,
  };
};
export const termsFetch = (skip, limit, myFilters) => {
  const data = {
    limit,
    skip,
    myFilters,
  };
  return (dispatch) => {
    dispatch(termsFetchStart());
    fetch(`${API}/terms/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ myFilters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let termCount = result.count;
        fetch(`${API}/terms`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(termsFetchFail(data.error));
            } else {
              dispatch(termsCountFetchSuccess(termCount));
              dispatch(termsFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(termsFetchFail(error));
          });
      });
  };
};

//get filtered terms
export const termsFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_TERMS_START,
  };
};
export const termsFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_TERMS_SUCCESS,
    terms: data,
  };
};
export const termsFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_TERMS_COUNT_SUCCESS,
    count: data,
  };
};
export const termsFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_TERMS_FAIL,
    error: error,
  };
};
export const termsFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(termsFilteredFetchStart());
    fetch(`${API}/terms/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let termsCount = result.count;
        fetch(`${API}/terms/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(termsFilteredFetchFail(data.error));
            } else {
              dispatch(termsFilteredFetchSuccess(data.data));
              dispatch(termsFilteredCountFetchSuccess(termsCount));
            }
          })
          .catch((error) => {
            dispatch(termsFilteredFetchFail(error));
          });
      });
  };
};

//get term by ID
export const termFetchStart = () => {
  return {
    type: actionTypes.FETCH_TERM_START,
  };
};
export const termFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_TERM_SUCCESS,
    term: data,
  };
};
export const termFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_TERM_FAIL,
    error: error,
  };
};
export const termFetch = (termId) => {
  return (dispatch) => {
    dispatch(termFetchStart());
    fetch(`${API}/term/${termId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => dispatch(termFetchSuccess(data)))
      .catch((error) => {
        dispatch(termFetchFail(error));
      });
  };
};

//create term
export const termCreateStart = () => {
  return {
    type: actionTypes.CREATE_TERM_START,
  };
};
export const termCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_TERM_SUCCESS,
  };
};
export const termCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_TERM_FAIL,
    error: error,
  };
};
export const termCreate = (userId, token, termData) => {
  return (dispatch) => {
    dispatch(termCreateStart());
    fetch(`${API}/term/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(termData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(termCreateFail(data.error));
        } else {
          dispatch(termCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(termCreateFail(error));
      });
  };
};

//update term
export const termUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_TERM_START,
  };
};
export const termUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_TERM_SUCCESS,
    term: data,
  };
};
export const termUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_TERM_FAIL,
    error: error,
  };
};

export const termUpdate = (termSlug, userId, token, termData) => {
  return (dispatch) => {
    dispatch(termUpdateStart());
    fetch(`${API}/term/update/${termSlug}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: termData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(termUpdateFail(data.error));
        } else {
          dispatch(termUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(termUpdateFail(error));
      });
  };
};

//delete term
export const termDeleteStart = () => {
  return {
    type: actionTypes.DELETE_TERM_START,
  };
};
export const termDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_TERM_SUCCESS,
  };
};
export const termDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_TERM_FAIL,
    error: error,
  };
};
export const termDelete = (termSlug, userId, token) => {
  return (dispatch) => {
    dispatch(termDeleteStart());
    fetch(`${API}/term/${termSlug}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(termDeleteFail(data.error));
        } else {
          dispatch(termDeleteSuccess());
        }
      })
      .catch((err) => {
        dispatch(termDeleteFail(err));
      });
  };
};

//clear term message
export const termMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_TERM_MESSAGE_SUCCESS,
  };
};
export const termMessageClear = () => {
  return (dispatch) => {
    dispatch(termMessageClearSuccess());
  };
};
