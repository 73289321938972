import { combineReducers } from "redux";

import userReducer from "./userReducer";
import contactReducer from "./contactReducer";
import mainBannerReducer from "./mainBannersReducer";
import blogPostReducer from "../reducers/blogPostReducer";
import blogCategoryReducer from "../reducers/blogCategoryReducer";
import imageReducer from "../reducers/imageReducer";
import orderReducer from "../reducers/orderReducer";
import packageReducer from "../reducers/packageReducer";
import categoryReducer from "../reducers/categoryReducer";
import subcategoryReducer from "../reducers/subcategoryReducer";
import questionReducer from "../reducers/questionReducer";
import subscriberReducer from "../reducers/subscriberReducer";
import termReducer from "../reducers/termReducer";

const rootReducer = combineReducers({
  user: userReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  question: questionReducer,
  term: termReducer,
  contact: contactReducer,
  mainBanner: mainBannerReducer,
  blogPost: blogPostReducer,
  blogCategory: blogCategoryReducer,
  image: imageReducer,
  order: orderReducer,
  package: packageReducer,
  subscriber: subscriberReducer,
});

export default rootReducer;
