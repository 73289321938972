import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get main banner by ID
export const mainBannerFetchStart = () => {
  return {
    type: actionTypes.FETCH_MAIN_BANNER_START,
  };
};
export const mainBannerFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MAIN_BANNER_SUCCESS,
    mainBanner: data,
  };
};
export const mainBannerFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_MAIN_BANNER_FAIL,
    error: error,
  };
};
export const mainBannerFetch = (mainBannerId) => {
  return (dispatch) => {
    dispatch(mainBannerFetchStart());
    fetch(`${API}/mainBanner/${mainBannerId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => dispatch(mainBannerFetchSuccess(data)))
      .catch((err) => {
        dispatch(mainBannerFetchFail(err));
      });
  };
};

//get filtered main banners
export const mainBannersFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_MAIN_BANNERS_START,
  };
};
export const mainBannersFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_MAIN_BANNERS_SUCCESS,
    mainBanners: data,
  };
};
export const mainBannersFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_MAIN_BANNERS_COUNT_SUCCESS,
    count: data,
  };
};
export const mainBannersFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_MAIN_BANNERS_FAIL,
    error: error,
  };
};
export const mainBannersFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(mainBannersFilteredFetchStart());
    fetch(`${API}/mainBanners/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let mainBannersCount = result.count;
        fetch(`${API}/mainBanners/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(mainBannersFilteredFetchFail(data.error));
            } else {
              dispatch(mainBannersFilteredFetchSuccess(data.data));
              dispatch(mainBannersFilteredCountFetchSuccess(mainBannersCount));
            }
          })
          .catch((err) => {
            dispatch(mainBannersFilteredFetchFail(err));
          });
      });
  };
};

//create main banner
export const mainBannerCreateStart = () => {
  return {
    type: actionTypes.CREATE_MAIN_BANNER_START,
  };
};
export const mainBannerCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_MAIN_BANNER_SUCCESS,
  };
};
export const mainBannerCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_MAIN_BANNER_FAIL,
    error: error,
  };
};
export const mainBannerCreate = (userId, token, mainBanner) => {
  return (dispatch) => {
    dispatch(mainBannerCreateStart());
    fetch(`${API}/mainBanner/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: mainBanner,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(mainBannerCreateFail(data.error));
        } else {
          dispatch(mainBannerCreateSuccess());
        }
      })
      .catch((err) => {
        dispatch(mainBannerCreateFail(err));
      });
  };
};

//update main banner
export const mainBannerUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_MAIN_BANNER_START,
  };
};
export const mainBannerUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_MAIN_BANNER_SUCCESS,
    mainBanner: data,
  };
};
export const mainBannerUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_MAIN_BANNER_FAIL,
    error: error,
  };
};
export const mainBannerUpdate = (
  mainBannerId,
  userId,
  token,
  mainBannerData
) => {
  return (dispatch) => {
    dispatch(mainBannerUpdateStart());
    fetch(`${API}/mainBanner/update/${mainBannerId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: mainBannerData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(mainBannerUpdateFail(data.error));
        } else {
          dispatch(mainBannerUpdateSuccess(data));
        }
      })
      .catch((err) => {
        dispatch(mainBannerUpdateFail(err));
      });
  };
};

//delete main banner
export const mainBannerDeleteStart = () => {
  return {
    type: actionTypes.DELETE_MAIN_BANNER_START,
  };
};
export const mainBannerDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_MAIN_BANNER_SUCCESS,
  };
};
export const mainBannerDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_MAIN_BANNER_FAIL,
    error: error,
  };
};
export const mainBannerDelete = (mainBannerId, userId, token) => {
  return (dispatch) => {
    dispatch(mainBannerDeleteStart());
    fetch(`${API}/mainBanner/${mainBannerId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(mainBannerDeleteFail(data.error));
        } else {
          dispatch(mainBannerDeleteSuccess());
        }
      })
      .catch((err) => {
        dispatch(mainBannerDeleteFail(err));
      });
  };
};

//clear main banner message
export const clearMainBannerMessageSuccess = () => {
  return {
    type: actionTypes.CLEAR_MAIN_BANNER_MESSAGE_SUCCESS,
  };
};
export const clearMainBannerMessage = () => {
  return (dispatch) => {
    dispatch(clearMainBannerMessageSuccess());
  };
};
