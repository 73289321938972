import React from "react";
import { Row, Col, Card } from "reactstrap";
import classnames from "classnames";
import { FaCheckCircle } from "react-icons/fa";
import Loader from "../../../components/layouts/Loader";

const Step3 = ({ packages, packagesLoading, next, handleClick, values }) => {
  let renderPackages = (packages) =>
    packages.map((item, i) => {
      i += 1;
      let packageFeatures = [];
      let parsedPackageFeatures = JSON.parse(item.features);
      Object.entries(parsedPackageFeatures).map(([j, item]) =>
        packageFeatures.push(
          <ul key={j}>
            <li>
              <FaCheckCircle size={20} className="mr-2" />
              {item.description}
            </li>
          </ul>
        )
      );
      return (
        <Col xs={12} lg={4} className="text-center mb-4">
          <Card
            key={i}
            className="card-block clickable h-100"
            onClick={() => {
              handleClick("package", item);
              next();
            }}
          >
            <Row className="text-center mx-auto">
              <Col xs={12}></Col>
              <Col xs={12}>
                <p>{item.name}</p>
              </Col>
              <Col xs={12}>{packageFeatures}</Col>
            </Row>
          </Card>
        </Col>
      );
    });

  return (
    <Row>
      <Col xs={12} className="text-center mb-4">
        <p>Изберете соодветен пакет за советување</p>
      </Col>
      {packages && !packagesLoading ? (
        <React.Fragment>{renderPackages(packages)}</React.Fragment>
      ) : (
        <Col>
          <Loader />
        </Col>
      )}
    </Row>
  );
};

export default Step3;
