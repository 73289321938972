import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  package: null,
  packageSuccess: null,
  packageError: null,
  packageLoading: false,

  packages: null,
  packagesSuccess: null,
  packagesError: null,
  packagesLoading: false,

  packagesFiltered: null,
  packagesFilteredCount: null,
  packagesFilteredSuccess: null,
  packagesFilteredError: null,
  packagesFilteredLoading: false,

  packageUpdateSuccess: null,
  packageUpdateError: null,
  packageUpdateLoading: false,

  packageDeleteSuccess: null,
  packageDeleteError: null,
  packageDeleteLoading: false,
};

//get all package
const packagesFetchStart = (state) => {
  return updateObject(state, {
    packagesSuccess: null,
    packagesError: null,
    packagesLoading: true,
  });
};
const packagesFetchSuccess = (state, action) => {
  return updateObject(state, {
    packages: action.packages,
    packagesSuccess: true,
    packagesError: null,
    packagesLoading: false,
  });
};
const packagesFetchFail = (state, action) => {
  return updateObject(state, {
    packagesError: action.error,
    packagesSuccess: false,
    packagesLoading: false,
  });
};

//get filtered packages
const packagesFilteredFetchStart = (state) => {
  return updateObject(state, {
    packagesFilteredSuccess: null,
    packagesFilteredError: null,
    packagesFilteredLoading: true,
  });
};
const packagesFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    packagesFiltered: action.packages,
    packagesFilteredSuccess: true,
    packagesFilteredError: null,
    packagesFilteredLoading: false,
  });
};
const packagesFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    packagesFilteredCount: action.count,
  });
};
const packagesFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    packagesFilteredError: action.error,
    packagesFilteredSuccess: false,
    packagesFilteredLoading: false,
  });
};

//get package by Id
const packageFetchStart = (state) => {
  return updateObject(state, {
    packageSuccess: null,
    packageError: null,
    packageLoading: true,
  });
};
const packageFetchSuccess = (state, action) => {
  return updateObject(state, {
    package: action.package,
    packageSuccess: true,
    packageError: null,
    packageLoading: false,
  });
};
const packageFetchFail = (state, action) => {
  return updateObject(state, {
    packageError: action.error,
    packageSuccess: false,
    packageLoading: false,
  });
};

//create package
const packageCreateStart = (state) => {
  return updateObject(state, {
    packageSuccess: null,
    packageError: null,
    packageLoading: true,
  });
};
const packageCreateSuccess = (state) => {
  return updateObject(state, {
    packageSuccess: true,
    packageError: null,
    packageLoading: false,
  });
};
const packageCreateFail = (state, action) => {
  return updateObject(state, {
    packageError: action.error,
    packageSuccess: false,
    packageLoading: false,
  });
};

//update package
const packageUpdateStart = (state) => {
  return updateObject(state, {
    packageUpdateSuccess: null,
    packageUpdateError: null,
    packageUpdateLoading: true,
  });
};
const packageUpdateSuccess = (state, action) => {
  return updateObject(state, {
    package: action.package,
    packageUpdateSuccess: true,
    packageUpdateError: null,
    packageUpdateLoading: false,
  });
};
const packageUpdateFail = (state, action) => {
  return updateObject(state, {
    packageUpdateError: action.error,
    packageUpdateSuccess: false,
    packageUpdateLoading: false,
  });
};

//delete package
const packageDeleteStart = (state) => {
  return updateObject(state, {
    packageDeleteSuccess: null,
    packageDeleteError: null,
    packageDeleteLoading: true,
  });
};
const packageDeleteSuccess = (state) => {
  return updateObject(state, {
    packageDeleteSuccess: true,
    packageDeleteError: null,
    packageDeleteLoading: false,
  });
};
const packageDeleteFail = (state, action) => {
  return updateObject(state, {
    packageDeleteError: action.error,
    packageDeleteSuccess: false,
    packageDeleteLoading: false,
  });
};

//clear package message
const packageMessageClearSuccess = (state) => {
  return updateObject(state, {
    packageSuccess: null,
    packageError: null,
    packagesSuccess: null,
    packagesError: null,
    packageUpdateSuccess: null,
    packageUpdateError: null,
    packageDeleteSuccess: null,
    packageDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGES_START:
      return packagesFetchStart(state);
    case actionTypes.FETCH_PACKAGES_SUCCESS:
      return packagesFetchSuccess(state, action);
    case actionTypes.FETCH_PACKAGES_FAIL:
      return packagesFetchFail(state, action);

    case actionTypes.FETCH_PACKAGE_START:
      return packageFetchStart(state);
    case actionTypes.FETCH_PACKAGE_SUCCESS:
      return packageFetchSuccess(state, action);
    case actionTypes.FETCH_PACKAGE_FAIL:
      return packageFetchFail(state, action);

    case actionTypes.FETCH_PACKAGES_FILTERED_START:
      return packagesFilteredFetchStart(state);
    case actionTypes.FETCH_PACKAGES_FILTERED_SUCCESS:
      return packagesFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_PACKAGES_FILTERED_COUNT_SUCCESS:
      return packagesFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_PACKAGES_FILTERED_FAIL:
      return packagesFilteredFetchFail(state, action);

    case actionTypes.CREATE_PACKAGE_START:
      return packageCreateStart(state);
    case actionTypes.CREATE_PACKAGE_SUCCESS:
      return packageCreateSuccess(state);
    case actionTypes.CREATE_PACKAGE_FAIL:
      return packageCreateFail(state, action);

    case actionTypes.UPDATE_PACKAGE_START:
      return packageUpdateStart(state);
    case actionTypes.UPDATE_PACKAGE_SUCCESS:
      return packageUpdateSuccess(state, action);
    case actionTypes.UPDATE_PACKAGE_FAIL:
      return packageUpdateFail(state, action);

    case actionTypes.DELETE_PACKAGE_START:
      return packageDeleteStart(state);
    case actionTypes.DELETE_PACKAGE_SUCCESS:
      return packageDeleteSuccess(state);
    case actionTypes.DELETE_PACKAGE_FAIL:
      return packageDeleteFail(state, action);

    case actionTypes.CLEAR_PACKAGE_MESSAGE_SUCCESS:
      return packageMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
