import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  question: null,
  questionSuccess: null,
  questionError: null,
  questionLoading: false,

  questions: null,
  questionsSuccess: null,
  questionsError: null,
  questionsLoading: false,

  questionUpdateSuccess: null,
  questionUpdateError: null,
  questionUpdateLoading: false,

  questionDeleteSuccess: null,
  questionDeleteError: null,
  questionDeleteLoading: false,

  questionsFiltered: null,
  questionsFilteredCount: null,
  questionsFilteredSuccess: null,
  questionsFilteredError: null,
  questionsFilteredLoading: false,
};

//get all questions
const questionsFetchStart = (state) => {
  return updateObject(state, {
    questionsSuccess: null,
    questionsError: null,
    questionsLoading: true,
  });
};
const questionsFetchSuccess = (state, action) => {
  return updateObject(state, {
    questions: action.questions,
    questionsSuccess: true,
    questionsError: null,
    questionsLoading: false,
  });
};
const questionsFetchFail = (state, action) => {
  return updateObject(state, {
    questionsError: action.error,
    questionsSuccess: false,
    questionsLoading: false,
  });
};

//get filtered questions
const questionsFilteredFetchStart = (state) => {
  return updateObject(state, {
    questionsFilteredSuccess: null,
    questionsFilteredError: null,
    questionsFilteredLoading: true,
  });
};
const questionsFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    questionsFiltered: action.questions,
    questionsFilteredSuccess: true,
    questionsFilteredError: null,
    questionsFilteredLoading: false,
  });
};
const questionsFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    questionsFilteredCount: action.count,
  });
};
const questionsFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    questionsFilteredError: action.error,
    questionsFilteredSuccess: false,
    questionsFilteredLoading: false,
  });
};

//get question by Slug
const questionFetchStart = (state) => {
  return updateObject(state, {
    questionSuccess: null,
    questionError: null,
    questionLoading: true,
  });
};
const questionFetchSuccess = (state, action) => {
  return updateObject(state, {
    question: action.question,
    questionSuccess: true,
    questionError: null,
    questionLoading: false,
  });
};
const questionFetchFail = (state, action) => {
  return updateObject(state, {
    questionError: action.error,
    questionSuccess: false,
    questionLoading: false,
  });
};

//create question
const questionCreateStart = (state) => {
  return updateObject(state, {
    questionSuccess: null,
    questionError: null,
    questionLoading: true,
  });
};
const questionCreateSuccess = (state) => {
  return updateObject(state, {
    questionSuccess: true,
    questionError: null,
    questionLoading: false,
  });
};
const questionCreateFail = (state, action) => {
  return updateObject(state, {
    questionError: action.error,
    questionSuccess: false,
    questionLoading: false,
  });
};

//update question
const questionUpdateStart = (state) => {
  return updateObject(state, {
    questionUpdateSuccess: null,
    questionUpdateError: null,
    questionUpdateLoading: true,
  });
};
const questionUpdateSuccess = (state, action) => {
  return updateObject(state, {
    question: action.question,
    questionUpdateSuccess: true,
    questionUpdateError: null,
    questionUpdateLoading: false,
  });
};
const questionUpdateFail = (state, action) => {
  return updateObject(state, {
    questionUpdateError: action.error,
    questionUpdateSuccess: false,
    questionUpdateLoading: false,
  });
};

//delete question
const questionDeleteStart = (state) => {
  return updateObject(state, {
    questionDeleteSuccess: null,
    questionDeleteError: null,
    questionDeleteLoading: true,
  });
};
const questionDeleteSuccess = (state) => {
  return updateObject(state, {
    questionDeleteSuccess: true,
    questionDeleteError: null,
    questionDeleteLoading: false,
  });
};
const questionDeleteFail = (state, action) => {
  return updateObject(state, {
    questionDeleteError: action.error,
    questionDeleteSuccess: false,
    questionDeleteLoading: false,
  });
};

//clear question message
const questionMessageClearSuccess = (state) => {
  return updateObject(state, {
    questionSuccess: null,
    questionError: null,
    questionsSuccess: null,
    questionsError: null,
    questionsFilteredSuccess: null,
    questionsFilteredError: null,
    questionUpdateSuccess: null,
    questionUpdateError: null,
    questionDeleteSuccess: null,
    questionDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_QUESTION_START:
      return questionFetchStart(state);
    case actionTypes.FETCH_QUESTION_SUCCESS:
      return questionFetchSuccess(state, action);
    case actionTypes.FETCH_QUESTION_FAIL:
      return questionFetchFail(state, action);

    case actionTypes.FETCH_QUESTIONS_START:
      return questionsFetchStart(state);
    case actionTypes.FETCH_QUESTIONS_SUCCESS:
      return questionsFetchSuccess(state, action);
    case actionTypes.FETCH_QUESTIONS_FAIL:
      return questionsFetchFail(state, action);

    case actionTypes.FETCH_FILTERED_QUESTIONS_START:
      return questionsFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_QUESTIONS_SUCCESS:
      return questionsFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_QUESTIONS_COUNT_SUCCESS:
      return questionsFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_QUESTIONS_FAIL:
      return questionsFilteredFetchFail(state, action);

    case actionTypes.CREATE_QUESTION_START:
      return questionCreateStart(state);
    case actionTypes.CREATE_QUESTION_SUCCESS:
      return questionCreateSuccess(state);
    case actionTypes.CREATE_QUESTION_FAIL:
      return questionCreateFail(state, action);

    case actionTypes.UPDATE_QUESTION_START:
      return questionUpdateStart(state);
    case actionTypes.UPDATE_QUESTION_SUCCESS:
      return questionUpdateSuccess(state, action);
    case actionTypes.UPDATE_QUESTION_FAIL:
      return questionUpdateFail(state, action);

    case actionTypes.DELETE_QUESTION_START:
      return questionDeleteStart(state);
    case actionTypes.DELETE_QUESTION_SUCCESS:
      return questionDeleteSuccess(state);
    case actionTypes.DELETE_QUESTION_FAIL:
      return questionDeleteFail(state, action);

    case actionTypes.CLEAR_QUESTION_MESSAGE_SUCCESS:
      return questionMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
