import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import AuthModal from "../components/sections/Modals/AuthModal";

const Layout = ({ children, isAuth }) => {
  const location = useLocation();
  const [authModal, setAuthModal] = useState(false);
  const [mode, setMode] = useState("register");
  const [token, setToken] = useState();

  const toggleAuthModal = () => {
    setAuthModal(!authModal);
  };

  useEffect(() => {
    if (location) {
      const tokenType = location.pathname.split("/")[2];
      const token = location.pathname.split("/")[3];

      if (tokenType === "активација" && token) {
        setMode("login");
        setAuthModal(true);
      } else if (tokenType === "ресетирање" && token) {
        setMode("reset");
        setAuthModal(true);
      }
      setToken(token);
    }
  }, [location]);

  return (
    <React.Fragment>
      <Header
        isAuth={isAuth}
        toggleAuthModal={toggleAuthModal}
        setMode={setMode}
      />
      {children}
      <Footer />
      <AuthModal
        authModal={authModal}
        toggleAuthModal={toggleAuthModal}
        setAuthModal={setAuthModal}
        mode={mode}
        setMode={setMode}
        token={token}
      />
    </React.Fragment>
  );
};

export default Layout;
