import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all categories
export const categoriesFetchStart = () => {
  return {
    type: actionTypes.FETCH_CATEGORIES_START,
  };
};
export const categoriesFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_CATEGORIES_SUCCESS,
    categories: data.data,
  };
};
export const categoriesCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_CATEGORIES_COUNT_SUCCESS,
    count: data,
  };
};
export const categoriesFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_CATEGORIES_FAIL,
    error: error,
  };
};
export const categoriesFetch = (skip, limit, myFilters) => {
  const data = {
    limit,
    skip,
    myFilters,
  };

  return (dispatch) => {
    dispatch(categoriesFetchStart());
    fetch(`${API}/categories/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ myFilters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let categoryCount = result.count;
        fetch(`${API}/categories`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(categoriesFetchFail(data.error));
            } else {
              dispatch(categoriesCountFetchSuccess(categoryCount));
              dispatch(categoriesFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(categoriesFetchFail(error));
          });
      });
  };
};

//get filtered categories
export const categoriesFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_CATEGORIES_START,
  };
};
export const categoriesFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_CATEGORIES_SUCCESS,
    categories: data,
  };
};
export const categoriesFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_CATEGORIES_COUNT_SUCCESS,
    count: data,
  };
};
export const categoriesFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_CATEGORIES_FAIL,
    error: error,
  };
};
export const categoriesFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(categoriesFilteredFetchStart());
    fetch(`${API}/categories/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let categoriesCount = result.count;
        fetch(`${API}/categories/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(categoriesFilteredFetchFail(data.error));
            } else {
              dispatch(categoriesFilteredFetchSuccess(data.data));
              dispatch(categoriesFilteredCountFetchSuccess(categoriesCount));
            }
          })
          .catch((error) => {
            dispatch(categoriesFilteredFetchFail(error));
          });
      });
  };
};

//get category by Slug
export const categoryFetchStart = () => {
  return {
    type: actionTypes.FETCH_CATEGORY_START,
  };
};
export const categoryFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_CATEGORY_SUCCESS,
    category: data,
  };
};
export const categoryFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_CATEGORY_FAIL,
    error: error,
  };
};
export const categoryFetch = (categorySlug) => {
  return (dispatch) => {
    dispatch(categoryFetchStart());
    fetch(`${API}/category/${categorySlug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => dispatch(categoryFetchSuccess(data)))
      .catch((error) => {
        dispatch(categoryFetchFail(error));
      });
  };
};

//create category
export const categoryCreateStart = () => {
  return {
    type: actionTypes.CREATE_CATEGORY_START,
  };
};
export const categoryCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_CATEGORY_SUCCESS,
  };
};
export const categoryCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_CATEGORY_FAIL,
    error: error,
  };
};
export const categoryCreate = (userId, token, categoryData) => {
  return (dispatch) => {
    dispatch(categoryCreateStart());
    fetch(`${API}/category/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: categoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(categoryCreateFail(data.error));
        } else {
          dispatch(categoryCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(categoryCreateFail(error));
      });
  };
};

//update category
export const categoryUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_CATEGORY_START,
  };
};
export const categoryUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_SUCCESS,
    category: data,
  };
};
export const categoryUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_FAIL,
    error: error,
  };
};

export const categoryUpdate = (categorySlug, userId, token, categoryData) => {
  return (dispatch) => {
    dispatch(categoryUpdateStart());
    fetch(`${API}/category/update/${categorySlug}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: categoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(categoryUpdateFail(data.error));
        } else {
          dispatch(categoryUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(categoryUpdateFail(error));
      });
  };
};

//delete category
export const categoryDeleteStart = () => {
  return {
    type: actionTypes.DELETE_CATEGORY_START,
  };
};
export const categoryDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_CATEGORY_SUCCESS,
  };
};
export const categoryDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_CATEGORY_FAIL,
    error: error,
  };
};
export const categoryDelete = (categorySlug, userId, token) => {
  return (dispatch) => {
    dispatch(categoryDeleteStart());
    fetch(`${API}/category/${categorySlug}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(categoryDeleteFail(data.error));
        } else {
          dispatch(categoryDeleteSuccess());
        }
      })
      .catch((err) => {
        dispatch(categoryDeleteFail(err));
      });
  };
};

//clear category message
export const categoryMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_CATEGORY_MESSAGE_SUCCESS,
  };
};
export const categoryMessageClear = () => {
  return (dispatch) => {
    dispatch(categoryMessageClearSuccess());
  };
};
