import React from "react";
import { Row, Col, Modal, ModalBody, Button, Spinner, Alert } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import moment from "moment";
import * as actions from "../../../store/actions/exports";
import { connect } from "react-redux";

const ConfirmationModal = ({
  confirmationModal,
  toggleConfirmationModal,
  orderData,
  orderCreate,
  orderLoading,
  userLogged,
  orderSuccess,
}) => {
  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  return (
    <Modal fullscreen="lg" isOpen={confirmationModal} centered>
      <ModalBody>
        {orderData && (
          <Row className="justify-content-center">
            <Col xs={10} className="text-center">
              <h5>Дали сеуште сакате да закажете термин?</h5>
            </Col>
            <Col xs={10}>
              {orderSuccess &&
                showAlert(
                  "Терминот е успешно закажан. Ќе бидете пренасочени кон вашите термини.",
                  "success"
                )}
            </Col>

            <Col xs={10} className="text-center mb-4">
              {orderData.category.name}
            </Col>
            <Col xs={10} className="text-center mb-4">
              {orderData.subcategory.name}
            </Col>
            <Col xs={10} className="text-center mb-4">
              {orderData.package.name}
            </Col>
            <Col xs={10} className="text-center mb-4">
              {moment(orderData.term.startDate).format("MMMM Do HH") +
                ":" +
                moment(orderData.term.startDate).format("mm") +
                " - " +
                moment(orderData.term.endDate).format("HH") +
                ":" +
                moment(orderData.term.endDate).format("mm")}
            </Col>
            <Col xs={12} lg={6} className="mt-4 mt-lg-0">
              <Button
                className="btn-custom secondary"
                block
                onClick={() => {
                  localStorage.removeItem("orderData");
                  toggleConfirmationModal();
                }}
              >
                Откажи
              </Button>
            </Col>
            <Col xs={12} lg={6} className="mt-4 mt-lg-0">
              <Button
                className="btn-custom primary"
                block
                onClick={() => {
                  orderCreate(userLogged.user._id, userLogged.token, orderData);
                }}
              >
                {!orderLoading ? (
                  "Закажи"
                ) : (
                  <Spinner children={false} color="white" size="sm" />
                )}
              </Button>
            </Col>
          </Row>
        )}
        <div
          className="modal-close-button"
          onClick={() => {
            toggleConfirmationModal();
          }}
        >
          <FaTimes size={20} />
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    orderSuccess: state.order.orderSuccess,
    orderLoading: state.order.orderLoading,
    orderError: state.order.orderError,

    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    orderCreate: (userId, token, orderData) =>
      dispatch(actions.orderCreate(userId, token, orderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
