import React from "react";
import { Col, Row, FormGroup, Input, Alert } from "reactstrap";

const Step5 = ({
  handleChange,
  orderError,
  orderSuccess,
  confirmationModal,
}) => {
  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  return (
    <Row>
      <Col xs={12} className="text-center pb-4">
        <p>Пополнете го формуларот подолу</p>
      </Col>
      <Col xs={12} className="text-center">
        {orderError && showAlert(orderError, "danger")}
        {orderSuccess &&
          !confirmationModal &&
          showAlert(
            "Терминот е успешно закажан. Ќе бидете пренасочени кон вашите термини.",
            "success"
          )}
      </Col>
      <Col xs={12} className="m-auto">
        <FormGroup className="form-label-group">
          <Input
            type="text"
            name="name"
            placeholder="Внеси име и презиме..."
            id="name"
            onChange={handleChange("name")}
            required
          />
        </FormGroup>
        <FormGroup className="form-label-group">
          <Input
            type="email"
            name="email"
            placeholder="Внеси електронска пошта..."
            id="email"
            onChange={handleChange("email")}
            required
          />
        </FormGroup>
        <FormGroup className="form-label-group">
          <Input
            type="text"
            name="phone"
            placeholder="Внеси телефонски број..."
            id="phone"
            onChange={handleChange("phone")}
            required
          />
        </FormGroup>
        <FormGroup className="form-label-group">
          <Input
            type="textarea"
            rows={4}
            name="notes"
            placeholder="Внеси забелешки..."
            id="notes"
            onChange={handleChange("reason")}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default Step5;
