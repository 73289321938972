import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all packages
export const packagesFetchStart = () => {
  return {
    type: actionTypes.FETCH_PACKAGES_START,
  };
};
export const packagesFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PACKAGES_SUCCESS,
    packages: data,
  };
};
export const packagesFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PACKAGES_FAIL,
    error: error,
  };
};
export const packagesFetch = () => {
  return (dispatch) => {
    dispatch(packagesFetchStart());
    fetch(`${API}/packages`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(packagesFetchFail(data.error));
        } else {
          dispatch(packagesFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(packagesFetchFail(error));
      });
  };
};

//get filtered packages
export const packagesFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_PACKAGES_FILTERED_START,
  };
};
export const packagesFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PACKAGES_FILTERED_SUCCESS,
    packages: data,
  };
};
export const packagesFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PACKAGES_FILTERED_COUNT_SUCCESS,
    count: data,
  };
};
export const packagesFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PACKAGES_FILTERED_FAIL,
    error: error,
  };
};
export const packagesFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(packagesFilteredFetchStart());
    fetch(`${API}/packages/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let packagesCount = result.count;
        fetch(`${API}/packages/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(packagesFilteredFetchFail(data.error));
            } else {
              dispatch(packagesFilteredFetchSuccess(data.data));
              dispatch(packagesFilteredCountFetchSuccess(packagesCount));
            }
          })
          .catch((error) => {
            dispatch(packagesFilteredFetchFail(error));
          });
      });
  };
};

//get package by ID
export const packageFetchStart = () => {
  return {
    type: actionTypes.FETCH_PACKAGE_START,
  };
};
export const packageFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PACKAGE_SUCCESS,
    package: data,
  };
};
export const packageFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PACKAGE_FAIL,
    error: error,
  };
};
export const packageFetch = (packageId) => {
  return (dispatch) => {
    dispatch(packageFetchStart());
    fetch(`${API}/package/${packageId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => dispatch(packageFetchSuccess(data)))
      .catch((error) => {
        dispatch(packageFetchFail(error));
      });
  };
};

//create package
export const packageCreateStart = () => {
  return {
    type: actionTypes.CREATE_PACKAGE_START,
  };
};
export const packageCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_PACKAGE_SUCCESS,
  };
};
export const packageCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_PACKAGE_FAIL,
    error: error,
  };
};
export const packageCreate = (userId, token, packageData) => {
  return (dispatch) => {
    dispatch(packageCreateStart());
    fetch(`${API}/package/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: packageData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(packageCreateFail(data.error));
        } else {
          dispatch(packageCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(packageCreateFail(error));
      });
  };
};

//update package
export const packageUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_PACKAGE_START,
  };
};
export const packageUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PACKAGE_SUCCESS,
    package: data,
  };
};
export const packageUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_PACKAGE_FAIL,
    error: error,
  };
};

export const packageUpdate = (packageId, userId, token, packageData) => {
  return (dispatch) => {
    dispatch(packageUpdateStart());
    fetch(`${API}/package/update/${packageId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: packageData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(packageUpdateFail(data.error));
        } else {
          dispatch(packageUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(packageUpdateFail(error));
      });
  };
};

//delete package
export const packageDeleteStart = () => {
  return {
    type: actionTypes.DELETE_PACKAGE_START,
  };
};
export const packageDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_PACKAGE_SUCCESS,
  };
};
export const packageDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_PACKAGE_FAIL,
    error: error,
  };
};
export const packageDelete = (packageId, userId, token) => {
  return (dispatch) => {
    dispatch(packageDeleteStart());
    fetch(`${API}/package/${packageId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(packageDeleteFail(data.error));
        } else {
          dispatch(packageDeleteSuccess());
        }
      })
      .catch((err) => {
        dispatch(packageDeleteFail(err));
      });
  };
};

//clear package message
export const packageMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_PACKAGE_MESSAGE_SUCCESS,
  };
};
export const packageMessageClear = () => {
  return (dispatch) => {
    dispatch(packageMessageClearSuccess());
  };
};
