import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all subcategories
export const subcategoriesFetchStart = () => {
  return {
    type: actionTypes.FETCH_SUBCATEGORIES_START,
  };
};
export const subcategoriesFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_SUBCATEGORIES_SUCCESS,
    subcategories: data.data,
  };
};
export const subcategoriesCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_SUBCATEGORIES_COUNT_SUCCESS,
    count: data,
  };
};
export const subcategoriesFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_SUBCATEGORIES_FAIL,
    error: error,
  };
};
export const subcategoriesFetch = (skip, limit, myFilters) => {
  const data = {
    limit,
    skip,
    myFilters,
  };

  return (dispatch) => {
    dispatch(subcategoriesFetchStart());
    fetch(`${API}/subcategories/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ myFilters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let subcategoryCount = result.count;
        fetch(`${API}/subcategories`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(subcategoriesFetchFail(data.error));
            } else {
              dispatch(subcategoriesCountFetchSuccess(subcategoryCount));
              dispatch(subcategoriesFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(subcategoriesFetchFail(error));
          });
      });
  };
};

//get filtered subcategories
export const subcategoriesFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_SUBCATEGORIES_START,
  };
};
export const subcategoriesFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_SUBCATEGORIES_SUCCESS,
    subcategories: data,
  };
};
export const subcategoriesFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_SUBCATEGORIES_COUNT_SUCCESS,
    count: data,
  };
};
export const subcategoriesFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_SUBCATEGORIES_FAIL,
    error: error,
  };
};
export const subcategoriesFilteredFetch = (skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(subcategoriesFilteredFetchStart());
    fetch(`${API}/subcategories/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let subcategoriesCount = result.count;
        fetch(`${API}/subcategories/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(subcategoriesFilteredFetchFail(data.error));
            } else {
              dispatch(subcategoriesFilteredFetchSuccess(data.data));
              dispatch(
                subcategoriesFilteredCountFetchSuccess(subcategoriesCount)
              );
            }
          })
          .catch((error) => {
            dispatch(subcategoriesFilteredFetchFail(error));
          });
      });
  };
};

//get subcategory by Slug
export const subcategoryFetchStart = () => {
  return {
    type: actionTypes.FETCH_SUBCATEGORY_START,
  };
};
export const subcategoryFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_SUBCATEGORY_SUCCESS,
    subcategory: data,
  };
};
export const subcategoryFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_SUBCATEGORY_FAIL,
    error: error,
  };
};
export const subcategoryFetch = (subcategorySlug) => {
  return (dispatch) => {
    dispatch(subcategoryFetchStart());
    fetch(`${API}/subcategory/${subcategorySlug}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => dispatch(subcategoryFetchSuccess(data)))
      .catch((error) => {
        dispatch(subcategoryFetchFail(error));
      });
  };
};

//create subcategory
export const subcategoryCreateStart = () => {
  return {
    type: actionTypes.CREATE_SUBCATEGORY_START,
  };
};
export const subcategoryCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_SUBCATEGORY_SUCCESS,
  };
};
export const subcategoryCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_SUBCATEGORY_FAIL,
    error: error,
  };
};
export const subcategoryCreate = (userId, token, subcategoryData) => {
  return (dispatch) => {
    dispatch(subcategoryCreateStart());
    fetch(`${API}/subcategory/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: subcategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(subcategoryCreateFail(data.error));
        } else {
          dispatch(subcategoryCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(subcategoryCreateFail(error));
      });
  };
};

//update subcategory
export const subcategoryUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_SUBCATEGORY_START,
  };
};
export const subcategoryUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_SUBCATEGORY_SUCCESS,
    subcategory: data,
  };
};
export const subcategoryUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_SUBCATEGORY_FAIL,
    error: error,
  };
};

export const subcategoryUpdate = (
  subcategorySlug,
  userId,
  token,
  subcategoryData
) => {
  return (dispatch) => {
    dispatch(subcategoryUpdateStart());
    fetch(`${API}/subcategory/update/${subcategorySlug}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: subcategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(subcategoryUpdateFail(data.error));
        } else {
          dispatch(subcategoryUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(subcategoryUpdateFail(error));
      });
  };
};

//delete subcategory
export const subcategoryDeleteStart = () => {
  return {
    type: actionTypes.DELETE_SUBCATEGORY_START,
  };
};
export const subcategoryDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_SUBCATEGORY_SUCCESS,
  };
};
export const subcategoryDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_SUBCATEGORY_FAIL,
    error: error,
  };
};
export const subcategoryDelete = (subcategorySlug, userId, token) => {
  return (dispatch) => {
    dispatch(subcategoryDeleteStart());
    fetch(`${API}/subcategory/${subcategorySlug}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(subcategoryDeleteFail(data.error));
        } else {
          dispatch(subcategoryDeleteSuccess());
        }
      })
      .catch((err) => {
        dispatch(subcategoryDeleteFail(err));
      });
  };
};

//clear subcategory message
export const subcategoryMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_SUBCATEGORY_MESSAGE_SUCCESS,
  };
};
export const subcategoryMessageClear = () => {
  return (dispatch) => {
    dispatch(subcategoryMessageClearSuccess());
  };
};
