import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Container, Row, Col } from "reactstrap";
import Loader from "../../layouts/Loader";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/exports";
import { API } from "../../../store/config";
const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const Banner = ({
  mainBannersFilteredFetch,
  mainBannersFiltered,
  mainBannersFilteredLoading,
}) => {
  useEffect(() => {
    mainBannersFilteredFetch(0, 5, { status: "active" });
  }, [mainBannersFilteredFetch]);
  return (
    <Container>
      <Row>
        <Col lg={12}>
          {!mainBannersFilteredLoading && mainBannersFiltered ? (
            <div className="banner">
              <Slider className="banner-slider" {...settings}>
                {mainBannersFiltered.map((item, i) => (
                  <div key={i}>
                    <Row
                      className="justify-content-center align-items-center m-0 banner-slider-item"
                      style={{
                        backgroundImage: `linear-gradient(
                          rgba(	140, 198, 63, 0.8), 
                          rgba(	140, 198, 63, 0.8)
                        ), url(${API}/uploads/mainBanners/${item._id}/image.jpeg)`,
                      }}
                    >
                      <Col
                        xs={10}
                        lg={6}
                        className="text-center d-flex flex-column justify-content-center"
                      >
                        <h2>{item.title}</h2>
                        <p>{item.content}</p>
                        <Link to={item.link} className="btn-custom secondary">
                          Погледни
                        </Link>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    mainBannersFiltered: state.mainBanner.mainBannersFiltered,
    mainBannersFilteredLoading: state.mainBanner.mainBannersFilteredLoading,
    mainBannersFilteredError: state.mainBanner.mainBannersFilteredError,
    mainBannersFilteredSuccess: state.mainBanner.mainBannersFilteredSuccess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    mainBannersFilteredFetch: (skip, limit, filters) =>
      dispatch(actions.mainBannersFilteredFetch(skip, limit, filters)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
