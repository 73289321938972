import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Banner from "../sections/Home/Banner";
import Process from "../sections/Home/Process";
import FormSection from "../sections/Home/FormSection";
import Newsletter from "../sections/Home/Newsletter";
import Blog from "../sections/Home/Blog";
import Questions from "../sections/Home/Questions";

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <React.Fragment>
      <Banner />
      <Process />
      <Blog />
      <Questions />
      <FormSection />
      <Newsletter />
    </React.Fragment>
  );
};

export default HomePage;
