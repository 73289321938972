import React from "react";
import {
  Button,
  Spinner,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import * as actions from "../../store/actions/exports";
import { connect } from "react-redux";
import userImg from "../../assets/img/user.png";
import { API } from "../../store/config";
import { Link, useHistory } from "react-router-dom";
import { FaUser, FaBars } from "react-icons/fa";

const renderUserDropdown = (history, userSignout, userLogged) => {
  return (
    <DropdownMenu end className="user-menu">
      {userLogged.user.role === 1 && (
        <React.Fragment>
          <Link to={"/панел"}>
            <DropdownItem>
              <span className="align-middle">Панел</span>
            </DropdownItem>
          </Link>
          <DropdownItem divider />
        </React.Fragment>
      )}
      <Link to={"/акаунт/термини"}>
        <DropdownItem>
          <span className="align-middle">Мои термини</span>
        </DropdownItem>
      </Link>
      <DropdownItem divider />
      <Link to={"/поставки"}>
        <DropdownItem>
          <span className="align-middle">Поставки</span>
        </DropdownItem>
      </Link>
      <DropdownItem divider />
      <DropdownItem
        onClick={() => {
          userSignout();
          history.push("/");
        }}
      >
        <span className="align-middle">Одјави се</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = ({
  userLogged,
  userLoggedLoading,
  userSignout,
  toggleAuthModal,
  setMode,
  sidemenu,
  setSidemenu,
}) => {
  const history = useHistory();

  return (
    <ul className="navbar-settings">
      {userLogged ? (
        <UncontrolledDropdown tag="li">
          <DropdownToggle tag="a" className="navbar-user">
            <span>
              <img
                src={
                  userLogged.user.hasPhoto
                    ? `${API}/uploads/users/${userLogged.user._id}/avatar.jpeg`
                    : userImg
                }
                className="rounded clickable ml-2"
                height="50"
                width="50"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          {renderUserDropdown(history, userSignout, userLogged)}
        </UncontrolledDropdown>
      ) : (
        <Button
          className="btn-custom ml-2"
          onClick={() => {
            setMode("register");
            toggleAuthModal();
          }}
        >
          {!userLoggedLoading ? (
            <FaUser size={20} />
          ) : (
            <Spinner color="white" size="sm" />
          )}
        </Button>
      )}
      <Button
        className="btn-custom ml-2 d-block d-xl-none"
        onClick={() => setSidemenu(!sidemenu)}
      >
        <FaBars size={20} />
      </Button>
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogged: state.user.userLogged,
    userLoggedLoading: state.user.userLoggedLoading,
    userSignout: state.user.userSignout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSignout: () => dispatch(actions.userSignout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
