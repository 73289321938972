import { lazy } from "react";

const Home = lazy(() => import("./components/pages/HomePage"));
const Dashboard = lazy(() => import("./components/pages/DashboardPage"));
const AccountSettings = lazy(() =>
  import("./components/pages/AccountSettingsPage")
);
const Contact = lazy(() => import("./components/pages/ContactPage"));
const Privacy = lazy(() => import("./components/pages/PrivacyPage"));
const Blog = lazy(() => import("./components/pages/BlogPage"));
const Questions = lazy(() => import("./components/pages/QuestionsPage"));
const BlogDetails = lazy(() =>
  import("./components/pages/BlogPostDetailsPage")
);
const Account = lazy(() => import("./components/pages/AccountPage"));

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/панел",
    component: Dashboard,
    isAdminRoute: true,
    isProtected: true,
  },
  {
    path: "/панел/:section",
    component: Dashboard,
    isAdminRoute: true,
    isProtected: true,
  },
  {
    path: "/панел/:section/:sectionId",
    component: Dashboard,
    isAdminRoute: true,
    isProtected: true,
  },
  {
    path: "/поставки",
    component: AccountSettings,
    isProtected: true,
  },
  {
    path: "/поставки/:section",
    component: AccountSettings,
    isProtected: true,
  },
  {
    path: "/акаунт",
    component: Account,
    isProtected: true,
  },
  {
    path: "/акаунт/:section",
    component: Account,
    isProtected: true,
  },
  {
    path: "/акаунт/:section/:sectionId",
    component: Account,
    isProtected: true,
  },
  {
    path: "/блог",
    component: Blog,
  },
  {
    path: "/блог/:blogPostSlug",
    component: BlogDetails,
  },
  {
    path: "/блог/категорија/:categorySlug",
    component: Blog,
  },
  {
    path: "/прашања",
    component: Questions,
  },
  {
    path: "/контакт",
    component: Contact,
  },
  {
    path: "/автентикација/активација/:token",
    component: Home,
  },
  {
    path: "/автентикација/ресетирање/:token",
    component: Home,
  },
  {
    path: "/заштита-податоци",
    component: Privacy,
  },
];

export default routes;
