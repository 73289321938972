import React from "react";
import { Container, Row, Col } from "reactstrap";
import { BiChevronsRight, BiCheckDouble } from "react-icons/bi";
import classnames from "classnames";

const data = [
  {
    title: "Штотуку стартуваше нов бизнис?",
    content:
      "Имаше одлична идеја и се одлучи да стартуваш свој бизнис. Сега е потребно да научиш се за истиот.",
  },
  {
    title: "Бараш одговори на прашања?",
    content:
      "Влезе во бизнис светот без преголеми знаења. Имаш многу неодговорени прашања.",
  },
  {
    title: "Земи термин со професионалец",
    content:
      "Со еден клик добивате одговор на сите ваши прашања од удобноста на својот дом.",
  },
];

const Services = () => {
  return (
    <Container className="mt-4 mb-5">
      <Row>
        {data.map((item, i) => (
          <Col key={i} xs={12} lg={4}>
            <div className="card-block">
              <h5 className="title">{item.title}</h5>
              <p className="para">{item.content}</p>
              <div
                className={classnames("card-footer", {
                  dark: i + 1 === data.length,
                })}
              >
                <Row>
                  <Col xs={8}>
                    <h5>Чекор {i + 1}</h5>
                  </Col>
                  <Col xs={4} className="text-right">
                    {i + 1 === data.length ? (
                      <BiCheckDouble size={36} />
                    ) : (
                      <BiChevronsRight size={36} />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
