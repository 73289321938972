import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { API } from "../../../store/config";
import Loader from "../../layouts/Loader";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/exports";

const Blog = ({
  blogPostsFilteredFetch,
  blogPostsFiltered,
  blogPostsFilteredLoading,
  blogPostsFilteredCount,
}) => {
  const [limit] = useState(3);
  const [skip] = useState(0);
  const [myFilters] = useState({
    order: "asc",
    status: "active",
    title: "",
  });

  useEffect(() => {
    blogPostsFilteredFetch(skip, limit, myFilters);
  }, [blogPostsFilteredFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderBlogPosts = (blogPostsFiltered) =>
    blogPostsFiltered.map((item, i) => (
      <Col key={i} xs={12} lg={4}>
        <div className="card-block blog">
          <img
            src={`${API}/uploads/blog/${item._id}/photo.jpeg`}
            alt={item.name}
            className="image-round"
          />
          <h5 className="my-2">
            <Link to={`/блог/${item.slug}`}> {item.title} </Link>
          </h5>
          <Link to={`/блог/${item.slug}`} className="btn-custom primary">
            Погледни
          </Link>
        </div>
      </Col>
    ));

  return (
    <Container className="mb-5">
      <Row>
        <Col xs={12} className="text-center">
          <h4>Најнови статии</h4>
          <p>Читајте актуелни содржини од нашиот блог</p>
        </Col>
        {!blogPostsFilteredLoading ? (
          blogPostsFilteredCount > 0 &&
          blogPostsFiltered && (
            <Col xs={12}>
              <Row>{renderBlogPosts(blogPostsFiltered)} </Row>
              <Row>
                <Col xs={12} lg={6} className="mx-auto">
                  <Link to="/блог" className="btn-custom secondary">
                    Останати статии
                  </Link>
                </Col>
              </Row>
            </Col>
          )
        ) : (
          <Col>
            <Loader />
          </Col>
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    blogPostsFiltered: state.blogPost.blogPostsFiltered,
    blogPostsFilteredCount: state.blogPost.blogPostsFilteredCount,
    blogPostsFilteredSuccess: state.blogPost.blogPostsFilteredSuccess,
    blogPostsFilteredError: state.blogPost.blogPostsFilteredError,
    blogPostsFilteredLoading: state.blogPost.blogPostsFilteredLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    blogPostsFilteredFetch: (skip, limit, filters) =>
      dispatch(actions.blogPostsFilteredFetch(skip, limit, filters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
