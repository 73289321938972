import React from "react";
import { Row, Col, Card } from "reactstrap";
import Loader from "../../../components/layouts/Loader";
import { API } from "../../../store/config";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";

const params = {
  slidesPerView: 2,
  spaceBetween: 40,
};

const Step2 = ({
  next,
  handleClick,
  subcategoriesFiltered,
  subcategoriesFilteredLoading,
}) => {
  return (
    <Row className="justify-content-center">
      <Col xs={12} className="text-center mb-4">
        <p>Изберете соодветна подобласт</p>
      </Col>
      {!subcategoriesFilteredLoading && subcategoriesFiltered ? (
        subcategoriesFiltered.map((subcategory, i) => (
          <Col xs={12}>
            <Card
              key={i}
              className="card-block clickable"
              onClick={() => {
                handleClick("subcategory", subcategory);
                next();
              }}
            >
              <p>{subcategory.name}</p>
            </Card>
          </Col>
        ))
      ) : (
        <Loader />
      )}
    </Row>
  );
};

export default Step2;
