import React from "react";
import { Link } from "react-router-dom";

const Mobilemenu = ({ sidemenu, setSidemenu }) => {
  const navigationmenu = [
    {
      id: 1,
      linkText: "Почетна",
      link: "/",
    },
    {
      id: 2,
      linkText: "Блог",
      link: "/блог",
    },
    {
      id: 3,
      linkText: "Прашања",
      link: "/прашања",
    },
    {
      id: 4,
      linkText: "Закажи термин",
      link: "/закажи-термин",
    },
    {
      id: 5,
      linkText: "Контакт",
      link: "/контакт",
    },
  ];
  return (
    <ul>
      {navigationmenu.map((item, i) => (
        <li
          key={i}
          className="menu-item"
          onClick={() => {
            item.onClick && item.onClick();
            setSidemenu(!sidemenu);
          }}
        >
          <Link to={item.link}> {item.linkText} </Link>
        </li>
      ))}
    </ul>
  );
};

export default Mobilemenu;
