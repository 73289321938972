import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import Mobilemenu from "./Mobilemenu";
import logo from "../../assets/img/logo.png";
import { connect } from "react-redux";
import * as actions from "../../store/actions/exports";
import User from "./User";

const Header = ({ toggleAuthModal, setMode }) => {
  const [sidemenu, setSidemenu] = useState(false);
  return (
    <React.Fragment>
      <aside className={`aside aside-left ${sidemenu ? "open" : ""}`}>
        <Mobilemenu sidemenu={sidemenu} setSidemenu={setSidemenu} />
      </aside>
      <div
        className="aside-overlay aside-trigger-left"
        onClick={() => setSidemenu(!sidemenu)}
      />
      <header className="header">
        <div className="header-middle">
          <div className="container">
            <nav className="navbar">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="logo" />
              </Link>
              <Navigation />
              <User
                toggleAuthModal={toggleAuthModal}
                setMode={setMode}
                sidemenu={sidemenu}
                setSidemenu={setSidemenu}
              />
            </nav>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    user: state.user.userLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSignout: () => dispatch(actions.userSignout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
