import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../assets/img/logo.png";

const Footer = ({ user }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer text-center text-lg-left">
      <div className="footer-middle">
        <Container>
          <Row>
            <Col xs={12} lg={6} className="footer-widget mt-4 mt-lg-0">
              <Link to="/">
                <img src={logo} alt="logo" className="footer-logo mb-4" />
              </Link>
              <p>
                Консултирај.ме е единствената дигитална платформа каде можете да
                дојдете до одговорите на вашите прашања за вашиот постоечки или
                иден бизнис. Закажете термин од експерт во само еден клик.
              </p>
            </Col>
            <Col xs={12} lg={3} className="footer-widget">
              <h5>Страници</h5>
              <ul>
                <li>
                  <Link to="/">Почетна</Link>
                </li>
                <li>
                  <Link to="/блог">Блог</Link>
                </li>
                <li>
                  <Link to="/контакт">Контакт</Link>
                </li>
                <li>
                  <Link to="/заштита-податоци">Заштита на податоци</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={3} className="footer-widget mt-4 mt-lg-0">
              <h5>Контакт</h5>
              <ul>
                <li>
                  <a href="tel:078326051">Телефон: +389 78 326 051</a>
                </li>
                <li>
                  <a href="mailto:mile@mmrevizija.mk">
                    Е-маил: mile@mmrevizija.mk
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom mt-4">
        <Container>
          <div className="footer-copyright">
            <Row>
              <Col xs={12} lg={6} className="text-center text-lg-left">
                <p>
                  {`Консултирај ме © ${new Date().getFullYear()} `}
                  <span>
                    Развиено од
                    <a href="https://www.produkto.io/"> Produkto.io </a>
                  </span>
                </p>
              </Col>
              <Col
                xs={12}
                lg={6}
                className="text-center text-lg-right mb-4 mt-lg-0"
              >
                <Link
                  to="#"
                  className="back-to-top"
                  onClick={() => scrollToTop()}
                >
                  Врати се горе
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userLogged,
  };
};

export default connect(mapStateToProps, null)(Footer);
