import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { Row, Col, Button, Progress, Spinner } from "reactstrap";
import { FaArrowLeft, FaArrowRight, FaPaperPlane } from "react-icons/fa";

const Form = ({
  categories,
  categoriesLoading,
  subcategoriesFiltered,
  subcategoriesFilteredLoading,
  handleClick,
  values,
  packages,
  packagesLoading,
  termsFilteredFetch,
  termsFiltered,
  termsFilteredCount,
  termsFilteredLoading,
  handleChange,
  handleSubmit,
  orderLoading,
  orderError,
  orderSuccess,
  confirmationModal,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [progressPercent, setProgressPercent] = useState(20);

  const next = () => {
    let step = currentStep >= 4 ? 5 : currentStep + 1;
    setCurrentStep(step);
    setProgressPercent(progressPercent + 20);
  };

  const prev = () => {
    let step = currentStep <= 1 ? 1 : currentStep - 1;
    setCurrentStep(step);
    setProgressPercent(progressPercent - 20);
  };

  const previousButton = () => {
    return (
      <Button
        className="btn-custom primary"
        onClick={() => prev()}
        disabled={currentStep === 1}
      >
        <FaArrowLeft size={20} />
      </Button>
    );
  };

  const validityFields = () => {
    if (values.name && values.phone && values.email) {
      return false;
    }
    return true;
  };

  const nextButton = () => {
    return (
      <Button
        className="btn-custom primary"
        onClick={(e) => {
          if (currentStep === 5) {
            handleSubmit(e);
          } else {
            next();
          }
        }}
        disabled={
          (currentStep === 3 && !values.package) ||
          (currentStep === 1 && !values.category) ||
          (currentStep === 2 && !values.subcategory) ||
          (currentStep === 4 && !values.term) ||
          (currentStep === 5 && validityFields())
        }
      >
        {currentStep === 5 ? (
          !orderLoading ? (
            <FaPaperPlane size={20} />
          ) : confirmationModal ? (
            <FaPaperPlane size={20} />
          ) : (
            <Spinner color="white" size="sm" children={false} />
          )
        ) : (
          <FaArrowRight size={20} />
        )}
      </Button>
    );
  };

  const progressBar = (progress) => (
    <Progress color="success" className="w-100" value={progress} />
  );

  return (
    <React.Fragment>
      {currentStep === 1 && (
        <Step1
          next={next}
          handleClick={handleClick}
          categories={categories}
          categoriesLoading={categoriesLoading}
        />
      )}
      {currentStep === 2 && (
        <Step2
          handleClick={handleClick}
          next={next}
          subcategoriesFiltered={subcategoriesFiltered}
          subcategoriesFilteredLoading={subcategoriesFilteredLoading}
        />
      )}
      {currentStep === 3 && (
        <Step3
          handleClick={handleClick}
          next={next}
          packages={packages}
          packagesLoading={packagesLoading}
          values={values}
        />
      )}
      {currentStep === 4 && (
        <Step4
          values={values}
          termsFilteredFetch={termsFilteredFetch}
          termsFiltered={termsFiltered}
          termsFilteredCount={termsFilteredCount}
          termsFilteredLoading={termsFilteredLoading}
          handleClick={handleClick}
          next={next}
        />
      )}
      {currentStep === 5 && (
        <Step5
          handleChange={handleChange}
          values={values}
          handleSubmit={handleSubmit}
          orderLoading={orderLoading}
          orderError={orderError}
          orderSuccess={orderSuccess}
          confirmationModal={confirmationModal}
        />
      )}
      <Row className="form-progress">
        <Col xs={3} lg={2}>
          {previousButton()}
        </Col>
        <Col xs={6} lg={8}>
          {progressPercent && progressBar(progressPercent)}
        </Col>
        <Col xs={3} lg={2}>
          {nextButton()}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Form;
