import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all questions
export const questionsFetchStart = () => {
  return {
    type: actionTypes.FETCH_QUESTIONS_START,
  };
};
export const questionsFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_QUESTIONS_SUCCESS,
    questions: data,
  };
};
export const questionsFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_QUESTIONS_FAIL,
    error: error,
  };
};
export const questionsFetch = () => {
  return (dispatch) => {
    dispatch(questionsFetchStart());
    fetch(`${API}/questions`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(questionsFetchFail(data.error));
        } else {
          dispatch(questionsFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(questionsFetchFail(error));
      });
  };
};

//get filtered questions
export const questionsFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_QUESTIONS_START,
  };
};
export const questionsFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_QUESTIONS_SUCCESS,
    questions: data,
  };
};
export const questionsFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_QUESTIONS_COUNT_SUCCESS,
    count: data,
  };
};
export const questionsFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_QUESTIONS_FAIL,
    error: error,
  };
};
export const questionsFilteredFetch = (skip, limit, filters) => {
  const data = {
    skip,
    limit,
    filters,
  };
  return (dispatch) => {
    dispatch(questionsFilteredFetchStart());
    fetch(`${API}/questions/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters: filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let questionsCount = result.count;
        fetch(`${API}/questions/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(questionsFilteredFetchFail(data.error));
            } else {
              dispatch(questionsFilteredFetchSuccess(data.data));
              dispatch(questionsFilteredCountFetchSuccess(questionsCount));
            }
          })
          .catch((error) => {
            dispatch(questionsFilteredFetchFail(error));
          });
      });
  };
};

//get question by id
export const questionFetchStart = () => {
  return {
    type: actionTypes.FETCH_QUESTION_START,
  };
};
export const questionFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_QUESTION_SUCCESS,
    question: data,
  };
};
export const questionFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_QUESTION_FAIL,
    error: error,
  };
};
export const questionFetch = (questionId) => {
  return (dispatch) => {
    dispatch(questionFetchStart());
    fetch(`${API}/question/${questionId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => dispatch(questionFetchSuccess(data)))
      .catch((error) => {
        dispatch(questionFetchFail(error));
      });
  };
};

//create question
export const questionCreateStart = () => {
  return {
    type: actionTypes.CREATE_QUESTION_START,
  };
};
export const questionCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_QUESTION_SUCCESS,
  };
};
export const questionCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_QUESTION_FAIL,
    error: error,
  };
};
export const questionCreate = (userId, token, questionData) => {
  return (dispatch) => {
    dispatch(questionCreateStart());
    fetch(`${API}/question/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: questionData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(questionCreateFail(data.error));
        } else {
          dispatch(questionCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(questionCreateFail(error));
      });
  };
};

//update question
export const questionUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_QUESTION_START,
  };
};
export const questionUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_QUESTION_SUCCESS,
    question: data,
  };
};
export const questionUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_QUESTION_FAIL,
    error: error,
  };
};
export const questionUpdate = (questionId, userId, token, questionData) => {
  return (dispatch) => {
    dispatch(questionUpdateStart());
    fetch(`${API}/question/update/${questionId}/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: questionData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(questionUpdateFail(data.error));
        } else {
          dispatch(questionUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(questionUpdateFail(error));
      });
  };
};

//delete question
export const questionDeleteStart = () => {
  return {
    type: actionTypes.DELETE_QUESTION_START,
  };
};
export const questionDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_QUESTION_SUCCESS,
  };
};
export const questionDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_QUESTION_FAIL,
    error: error,
  };
};
export const questionDelete = (questionId, userId, token) => {
  return (dispatch) => {
    dispatch(questionDeleteStart());
    fetch(`${API}/question/${questionId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(questionDeleteFail(data.error));
        } else {
          dispatch(questionDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(questionDeleteFail(error));
      });
  };
};

//clear question message
export const questionMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_QUESTION_MESSAGE_SUCCESS,
  };
};
export const questionMessageClear = () => {
  return (dispatch) => {
    dispatch(questionMessageClearSuccess());
  };
};
