import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const ErrorPage = () => {
  return (
    <Container className="my-4 text-center full-height">
      <Row>
        <Col xs={12} lg={6} className="m-auto">
          <h1 className="large-text mb-0">404</h1>
          <h4>Страницата не е пронајдена!</h4>
          <p>
            Обидете се да пристапете до друга страница од нашето мени или
            контактирајте не преку некој од споделените комуникациски канали.
            Нашиот тим ќе ви асистира во навигација.
          </p>
          <Link to="/" className="btn-custom primary">
            Оди на почетна страна
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
