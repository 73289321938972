import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Form from "../Form/Form";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/exports";
import AuthModal from "../Modals/AuthModal";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { useHistory } from "react-router-dom";

const FormSection = ({
  categoriesFetch,
  categories,
  categoriesLoading,
  subcategoriesFilteredFetch,
  subcategoriesFiltered,
  subcategoriesFilteredLoading,
  packagesFetch,
  packages,
  packagesLoading,
  termsFilteredFetch,
  termsFiltered,
  termsFilteredLoading,
  termsFilteredCount,
  userLogged,
  userLoggedSuccess,
  orderCreate,
  orderLoading,
  orderSuccess,
  orderError,
}) => {
  let history = useHistory();
  const [values, setValues] = useState({
    category: "",
    subcategory: "",
    package: "",
    term: null,
    name: "",
    email: "",
    phone: "",
    reason: "",
  });
  const [orderData, setOrderData] = useState();
  const [authModal, setAuthModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [mode, setMode] = useState("login");
  const { category, subcategory } = values;

  const toggleAuthModal = () => {
    setAuthModal(!authModal);
  };

  const toggleConfirmationModal = (value) => {
    !value
      ? setConfirmationModal(value)
      : setConfirmationModal(!confirmationModal);
  };

  const handleClick = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const handleChange = (name) => (event) => {
    let value = event.target.value;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    categoriesFetch();
  }, [categoriesFetch]);

  useEffect(() => {
    if (category) {
      subcategoriesFilteredFetch(0, 20, {
        category: category._id,
      });
    }
  }, [category, subcategoriesFilteredFetch]);

  useEffect(() => {
    if (subcategory) {
      packagesFetch();
    }
  }, [subcategory]);

  useEffect(() => {
    let orderData = JSON.parse(localStorage.getItem("orderData"));
    if (orderData && userLogged && userLoggedSuccess) {
      setOrderData(orderData);
      toggleConfirmationModal(true);
    }
  }, [userLogged]);

  useEffect(() => {
    if (orderSuccess) {
      const timer = setTimeout(() => {
        localStorage.removeItem("orderData");
        toggleConfirmationModal(false);
        history.push("/акаунт/термини");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [orderSuccess]);

  const handleSubmit = (e) => {
    e.persist();
    if (!userLogged) {
      localStorage.setItem("orderData", JSON.stringify(values));
      toggleAuthModal();
    } else {
      orderCreate(userLogged.user._id, userLogged.token, values);
    }
  };

  return (
    <Container className="form-section" id="закажи-термин">
      <Row className="form-content">
        <Col xs={12} className="text-center ">
          <h4>Закажи термин</h4>
        </Col>
        <Col xs={12} className="mb-4">
          <Form
            categories={categories}
            categoriesLoading={categoriesLoading}
            subcategoriesFiltered={subcategoriesFiltered}
            subcategoriesFilteredLoading={subcategoriesFilteredLoading}
            handleClick={handleClick}
            handleSubmit={handleSubmit}
            values={values}
            packages={packages}
            packagesLoading={packagesLoading}
            termsFilteredFetch={termsFilteredFetch}
            termsFiltered={termsFiltered}
            termsFilteredCount={termsFilteredCount}
            termsFilteredLoading={termsFilteredLoading}
            handleChange={handleChange}
            orderLoading={orderLoading}
            orderSuccess={orderSuccess}
            orderError={orderError}
            confirmationModal={confirmationModal}
          />
        </Col>
      </Row>
      <AuthModal
        authModal={authModal}
        toggleAuthModal={toggleAuthModal}
        setAuthModal={setAuthModal}
        mode={mode}
        setMode={setMode}
      />
      <ConfirmationModal
        confirmationModal={confirmationModal}
        toggleConfirmationModal={toggleConfirmationModal}
        orderData={orderData}
        orderSuccess={orderSuccess}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    categoriesCount: state.category.categoriesCount,
    categoriesLoading: state.category.categoriesLoading,
    categoriesError: state.category.categoriesError,
    categoriesSuccess: state.category.categoriesSuccess,

    subcategoriesFiltered: state.subcategory.subcategoriesFiltered,
    subcategoriesFilteredCount: state.subcategory.subcategoriesFilteredCount,
    subcategoriesFilteredLoading:
      state.subcategory.subcategoriesFilteredLoading,
    subcategoriesFilteredError: state.subcategory.subcategoriesFilteredError,
    subcategoriesFilteredSuccess:
      state.subcategory.subcategoriesFilteredSuccess,

    packages: state.package.packages,
    packagesSuccess: state.package.packagesSuccess,
    packagesLoading: state.package.packagesLoading,
    packagesError: state.package.packagesError,

    termsFiltered: state.term.termsFiltered,
    termsFilteredCount: state.term.termsFilteredCount,
    termsFilteredLoading: state.term.termsFilteredLoading,
    termsFilteredError: state.term.termsFilteredError,
    termsFilteredSuccess: state.term.termsFilteredSuccess,

    orderSuccess: state.order.orderSuccess,
    orderLoading: state.order.orderLoading,
    orderError: state.order.orderError,

    userLogged: state.user.userLogged,
    userLoggedSuccess: state.user.userLoggedSuccess,
    userLoggedError: state.user.userLoggedError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    categoriesFetch: () => dispatch(actions.categoriesFetch()),
    subcategoriesFilteredFetch: (skip, limit, filters) =>
      dispatch(actions.subcategoriesFilteredFetch(skip, limit, filters)),
    packagesFetch: () => dispatch(actions.packagesFetch()),
    termsFilteredFetch: (skip, limit, filters) =>
      dispatch(actions.termsFilteredFetch(skip, limit, filters)),
    orderCreate: (userId, token, orderData) =>
      dispatch(actions.orderCreate(userId, token, orderData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSection);
