import React from "react";
import { Row, Col, Card } from "reactstrap";
import Loader from "../../../components/layouts/Loader";

const Step1 = ({ next, handleClick, categories, categoriesLoading }) => {
  return (
    <Row className="justify-content-center">
      <Col xs={12} className="text-center mb-4">
        <p>Изберете соодветна област</p>
      </Col>
      {categories && !categoriesLoading ? (
        categories.map((category, i) => (
          <Col xs={12} className="text-center">
            <Card
              key={i}
              className="card-block clickable"
              onClick={() => {
                handleClick("category", category);
                next();
              }}
            >
              <p>{category.name}</p>
            </Card>
          </Col>
        ))
      ) : (
        <Col>
          <Loader />
        </Col>
      )}
    </Row>
  );
};

export default Step1;
