import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  subcategory: null,
  subcategorySuccess: null,
  subcategoryError: null,
  subcategoryLoading: false,

  subcategories: null,
  subcategoriesCount: null,
  subcategoriesSuccess: null,
  subcategoriesError: null,
  subcategoriesLoading: false,

  subcategoriesFiltered: null,
  subcategoriesFilteredCount: null,
  subcategoriesFilteredSuccess: null,
  subcategoriesFilteredError: null,
  subcategoriesFilteredLoading: false,

  subcategoryUpdateSuccess: null,
  subcategoryUpdateError: null,
  subcategoryUpdateLoading: false,

  subcategoryDeleteSuccess: null,
  subcategoryDeleteError: null,
  subcategoryDeleteLoading: false,
};

//get all subcategories
const subcategoriesFetchStart = (state) => {
  return updateObject(state, {
    subcategoriesSuccess: null,
    subcategoriesError: null,
    subcategoriesLoading: true,
  });
};
const subcategoriesFetchSuccess = (state, action) => {
  return updateObject(state, {
    subcategories: action.subcategories,
    subcategoriesSuccess: true,
    subcategoriesError: null,
    subcategoriesLoading: false,
  });
};
const subcategoriesCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    subcategoriesCount: action.count,
  });
};
const subcategoriesFetchFail = (state, action) => {
  return updateObject(state, {
    subcategoriesError: action.error,
    subcategoriesSuccess: false,
    subcategoriesLoading: false,
  });
};

//get filtered subcategories
const subcategoriesFilteredFetchStart = (state) => {
  return updateObject(state, {
    subcategoriesFilteredSuccess: null,
    subcategoriesFilteredError: null,
    subcategoriesFilteredLoading: true,
  });
};
const subcategoriesFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    subcategoriesFiltered: action.subcategories,
    subcategoriesFilteredSuccess: true,
    subcategoriesFilteredError: null,
    subcategoriesFilteredLoading: false,
  });
};
const subcategoriesFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    subcategoriesFilteredCount: action.count,
  });
};
const subcategoriesFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    subcategoriesFilteredError: action.error,
    subcategoriesFilteredSuccess: false,
    subcategoriesFilteredLoading: false,
  });
};

//get subcategory by Name
const subcategoryFetchStart = (state) => {
  return updateObject(state, {
    subcategorySuccess: null,
    subcategoryError: null,
    subcategoryLoading: true,
  });
};
const subcategoryFetchSuccess = (state, action) => {
  return updateObject(state, {
    subcategory: action.subcategory,
    subcategorySuccess: true,
    subcategoryError: null,
    subcategoryLoading: false,
  });
};
const subcategoryFetchFail = (state, action) => {
  return updateObject(state, {
    subcategoryError: action.error,
    subcategorySuccess: false,
    subcategoryLoading: false,
  });
};

//create subcategory
const subcategoryCreateStart = (state) => {
  return updateObject(state, {
    subcategorySuccess: null,
    subcategoryError: null,
    subcategoryLoading: true,
  });
};
const subcategoryCreateSuccess = (state) => {
  return updateObject(state, {
    subcategorySuccess: true,
    subcategoryError: null,
    subcategoryLoading: false,
  });
};
const subcategoryCreateFail = (state, action) => {
  return updateObject(state, {
    subcategoryError: action.error,
    subcategorySuccess: false,
    subcategoryLoading: false,
  });
};

//update subcategory
const subcategoryUpdateStart = (state) => {
  return updateObject(state, {
    subcategoryUpdateSuccess: null,
    subcategoryUpdateError: null,
    subcategoryUpdateLoading: true,
  });
};
const subcategoryUpdateSuccess = (state, action) => {
  return updateObject(state, {
    subcategory: action.subcategory,
    subcategoryUpdateSuccess: true,
    subcategoryUpdateError: null,
    subcategoryUpdateLoading: false,
  });
};
const subcategoryUpdateFail = (state, action) => {
  return updateObject(state, {
    subcategoryUpdateError: action.error,
    subcategoryUpdateSuccess: false,
    subcategoryUpdateLoading: false,
  });
};

//delete subcategory
const subcategoryDeleteStart = (state) => {
  return updateObject(state, {
    subcategoryDeleteSuccess: null,
    subcategoryDeleteError: null,
    subcategoryDeleteLoading: true,
  });
};
const subcategoryDeleteSuccess = (state) => {
  return updateObject(state, {
    subcategoryDeleteSuccess: true,
    subcategoryDeleteError: null,
    subcategoryDeleteLoading: false,
  });
};
const subcategoryDeleteFail = (state, action) => {
  return updateObject(state, {
    subcategoryDeleteError: action.error,
    subcategoryDeleteSuccess: false,
    subcategoryDeleteLoading: false,
  });
};

//clear subcategory message
const subcategoryMessageClearSuccess = (state) => {
  return updateObject(state, {
    subcategorySuccess: null,
    subcategoryError: null,
    subcategoriesSuccess: null,
    subcategoriesError: null,
    subcategoriesFilteredSuccess: null,
    subcategoriesFilteredError: null,
    subcategoryUpdateSuccess: null,
    subcategoryUpdateError: null,
    subcategoryDeleteSuccess: null,
    subcategoryDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBCATEGORIES_START:
      return subcategoriesFetchStart(state);
    case actionTypes.FETCH_SUBCATEGORIES_SUCCESS:
      return subcategoriesFetchSuccess(state, action);
    case actionTypes.FETCH_SUBCATEGORIES_COUNT_SUCCESS:
      return subcategoriesCountFetchSuccess(state, action);
    case actionTypes.FETCH_SUBCATEGORIES_FAIL:
      return subcategoriesFetchFail(state, action);

    case actionTypes.FETCH_FILTERED_SUBCATEGORIES_START:
      return subcategoriesFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_SUBCATEGORIES_SUCCESS:
      return subcategoriesFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_SUBCATEGORIES_COUNT_SUCCESS:
      return subcategoriesFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_SUBCATEGORIES_FAIL:
      return subcategoriesFilteredFetchFail(state, action);

    case actionTypes.FETCH_SUBCATEGORY_START:
      return subcategoryFetchStart(state);
    case actionTypes.FETCH_SUBCATEGORY_SUCCESS:
      return subcategoryFetchSuccess(state, action);
    case actionTypes.FETCH_SUBCATEGORY_FAIL:
      return subcategoryFetchFail(state, action);

    case actionTypes.CREATE_SUBCATEGORY_START:
      return subcategoryCreateStart(state);
    case actionTypes.CREATE_SUBCATEGORY_SUCCESS:
      return subcategoryCreateSuccess(state);
    case actionTypes.CREATE_SUBCATEGORY_FAIL:
      return subcategoryCreateFail(state, action);

    case actionTypes.UPDATE_SUBCATEGORY_START:
      return subcategoryUpdateStart(state);
    case actionTypes.UPDATE_SUBCATEGORY_SUCCESS:
      return subcategoryUpdateSuccess(state, action);
    case actionTypes.UPDATE_SUBCATEGORY_FAIL:
      return subcategoryUpdateFail(state, action);

    case actionTypes.DELETE_SUBCATEGORY_START:
      return subcategoryDeleteStart(state);
    case actionTypes.DELETE_SUBCATEGORY_SUCCESS:
      return subcategoryDeleteSuccess(state);
    case actionTypes.DELETE_SUBCATEGORY_FAIL:
      return subcategoryDeleteFail(state, action);

    case actionTypes.CLEAR_SUBCATEGORY_MESSAGE_SUCCESS:
      return subcategoryMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
