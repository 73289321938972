import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  category: null,
  categorySuccess: null,
  categoryError: null,
  categoryLoading: false,

  categories: null,
  categoriesCount: null,
  categoriesSuccess: null,
  categoriesError: null,
  categoriesLoading: false,

  categoriesFiltered: null,
  categoriesFilteredCount: null,
  categoriesFilteredSuccess: null,
  categoriesFilteredError: null,
  categoriesFilteredLoading: false,

  categoryUpdateSuccess: null,
  categoryUpdateError: null,
  categoryUpdateLoading: false,

  categoryDeleteSuccess: null,
  categoryDeleteError: null,
  categoryDeleteLoading: false,
};

//get all categories
const categoriesFetchStart = (state) => {
  return updateObject(state, {
    categoriesSuccess: null,
    categoriesError: null,
    categoriesLoading: true,
  });
};
const categoriesFetchSuccess = (state, action) => {
  return updateObject(state, {
    categories: action.categories,
    categoriesSuccess: true,
    categoriesError: null,
    categoriesLoading: false,
  });
};
const categoriesCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    categoriesCount: action.count,
  });
};
const categoriesFetchFail = (state, action) => {
  return updateObject(state, {
    categoriesError: action.error,
    categoriesSuccess: false,
    categoriesLoading: false,
  });
};

//get filtered categories
const categoriesFilteredFetchStart = (state) => {
  return updateObject(state, {
    categoriesFilteredSuccess: null,
    categoriesFilteredError: null,
    categoriesFilteredLoading: true,
  });
};
const categoriesFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    categoriesFiltered: action.categories,
    categoriesFilteredSuccess: true,
    categoriesFilteredError: null,
    categoriesFilteredLoading: false,
  });
};
const categoriesFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    categoriesFilteredCount: action.count,
  });
};
const categoriesFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    categoriesFilteredError: action.error,
    categoriesFilteredSuccess: false,
    categoriesFilteredLoading: false,
  });
};

//get category by Slug
const categoryFetchStart = (state) => {
  return updateObject(state, {
    categorySuccess: null,
    categoryError: null,
    categoryLoading: true,
  });
};
const categoryFetchSuccess = (state, action) => {
  return updateObject(state, {
    category: action.category,
    categorySuccess: true,
    categoryError: null,
    categoryLoading: false,
  });
};
const categoryFetchFail = (state, action) => {
  return updateObject(state, {
    categoryError: action.error,
    categorySuccess: false,
    categoryLoading: false,
  });
};

//create category
const categoryCreateStart = (state) => {
  return updateObject(state, {
    categorySuccess: null,
    categoryError: null,
    categoryLoading: true,
  });
};
const categoryCreateSuccess = (state) => {
  return updateObject(state, {
    categorySuccess: true,
    categoryError: null,
    categoryLoading: false,
  });
};
const categoryCreateFail = (state, action) => {
  return updateObject(state, {
    categoryError: action.error,
    categorySuccess: false,
    categoryLoading: false,
  });
};

//update category
const categoryUpdateStart = (state) => {
  return updateObject(state, {
    categoryUpdateSuccess: null,
    categoryUpdateError: null,
    categoryUpdateLoading: true,
  });
};
const categoryUpdateSuccess = (state, action) => {
  return updateObject(state, {
    category: action.category,
    categoryUpdateSuccess: true,
    categoryUpdateError: null,
    categoryUpdateLoading: false,
  });
};
const categoryUpdateFail = (state, action) => {
  return updateObject(state, {
    categoryUpdateError: action.error,
    categoryUpdateSuccess: false,
    categoryUpdateLoading: false,
  });
};

//delete category
const categoryDeleteStart = (state) => {
  return updateObject(state, {
    categoryDeleteSuccess: null,
    categoryDeleteError: null,
    categoryDeleteLoading: true,
  });
};
const categoryDeleteSuccess = (state) => {
  return updateObject(state, {
    categoryDeleteSuccess: true,
    categoryDeleteError: null,
    categoryDeleteLoading: false,
  });
};
const categoryDeleteFail = (state, action) => {
  return updateObject(state, {
    categoryDeleteError: action.error,
    categoryDeleteSuccess: false,
    categoryDeleteLoading: false,
  });
};

//clear category message
const categoryMessageClearSuccess = (state) => {
  return updateObject(state, {
    categorySuccess: null,
    categoryError: null,
    categoriesSuccess: null,
    categoriesError: null,
    categoriesFilteredSuccess: null,
    categoriesFilteredError: null,
    categoryUpdateSuccess: null,
    categoryUpdateError: null,
    categoryDeleteSuccess: null,
    categoryDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES_START:
      return categoriesFetchStart(state);
    case actionTypes.FETCH_CATEGORIES_SUCCESS:
      return categoriesFetchSuccess(state, action);
    case actionTypes.FETCH_CATEGORIES_COUNT_SUCCESS:
      return categoriesCountFetchSuccess(state, action);
    case actionTypes.FETCH_CATEGORIES_FAIL:
      return categoriesFetchFail(state, action);

    case actionTypes.FETCH_FILTERED_CATEGORIES_START:
      return categoriesFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_CATEGORIES_SUCCESS:
      return categoriesFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_CATEGORIES_COUNT_SUCCESS:
      return categoriesFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_CATEGORIES_FAIL:
      return categoriesFilteredFetchFail(state, action);

    case actionTypes.FETCH_CATEGORY_START:
      return categoryFetchStart(state);
    case actionTypes.FETCH_CATEGORY_SUCCESS:
      return categoryFetchSuccess(state, action);
    case actionTypes.FETCH_CATEGORY_FAIL:
      return categoryFetchFail(state, action);

    case actionTypes.CREATE_CATEGORY_START:
      return categoryCreateStart(state);
    case actionTypes.CREATE_CATEGORY_SUCCESS:
      return categoryCreateSuccess(state);
    case actionTypes.CREATE_CATEGORY_FAIL:
      return categoryCreateFail(state, action);

    case actionTypes.UPDATE_CATEGORY_START:
      return categoryUpdateStart(state);
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return categoryUpdateSuccess(state, action);
    case actionTypes.UPDATE_CATEGORY_FAIL:
      return categoryUpdateFail(state, action);

    case actionTypes.DELETE_CATEGORY_START:
      return categoryDeleteStart(state);
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return categoryDeleteSuccess(state);
    case actionTypes.DELETE_CATEGORY_FAIL:
      return categoryDeleteFail(state, action);

    case actionTypes.CLEAR_CATEGORY_MESSAGE_SUCCESS:
      return categoryMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
