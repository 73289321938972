import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  Collapse,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../../layouts/Loader";
import ReactHtmlParser from "react-html-parser";
import draftToHtml from "draftjs-to-html";
import { FiChevronDown } from "react-icons/fi";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/exports";

const Questions = ({
  questionsFiltered,
  questionsFilteredLoading,
  questionsFilteredCount,
  questionMessageClear,
  questionsFilteredFetch,
}) => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const [limit] = useState(6);
  const [skip] = useState(0);
  const [page] = useState(1);
  const [myFilters] = useState({
    order: "asc",
    status: "active",
    title: "",
  });

  useEffect(() => {
    questionMessageClear();

    questionsFilteredFetch(skip, limit, myFilters);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleActiveQuestion = (key) => {
    if (activeQuestion === null) {
      setActiveQuestion(key);
    } else if (activeQuestion !== key) {
      setActiveQuestion(key);
    } else {
      setActiveQuestion(null);
    }
  };

  const renderQuestions = (questionsFiltered) =>
    questionsFiltered.map((item, i) => (
      <Col xs={12} className="mb-4">
        <Card>
          <CardHeader onClick={() => handleActiveQuestion(i)}>
            {item.name}
            <FiChevronDown className="collapse-icon" size={20} />
          </CardHeader>
          <Collapse isOpen={activeQuestion === i ? true : false}>
            <CardBody>
              {ReactHtmlParser(draftToHtml(JSON.parse(item.answer)))}
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    ));

  return (
    <Container className="mb-5">
      <Row className="faq-content">
        <Col xs={12} className="text-center ">
          <h4>Прашања</h4>
          <p>Добијте одговори на чести прашања</p>
        </Col>
        {!questionsFilteredLoading ? (
          questionsFilteredCount > 0 &&
          questionsFiltered && (
            <React.Fragment>
              {renderQuestions(questionsFiltered)}
              <Col xs={12} lg={6} className="mx-auto">
                <Link to="/прашања" className="btn-custom secondary">
                  Останати прашања
                </Link>
              </Col>
            </React.Fragment>
          )
        ) : (
          <Col>
            <Loader />
          </Col>
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    questionsFiltered: state.question.questionsFiltered,
    questionsFilteredCount: state.question.questionsFilteredCount,
    questionsFilteredSuccess: state.question.questionsFilteredSuccess,
    questionsFilteredError: state.question.questionsFilteredError,
    questionsFilteredLoading: state.question.questionsFilteredLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    questionsFilteredFetch: (skip, limit, filters) =>
      dispatch(actions.questionsFilteredFetch(skip, limit, filters)),
    questionMessageClear: () => dispatch(actions.questionMessageClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
