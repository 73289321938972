import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Alert,
  FormGroup,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as actions from "../../../store/actions/exports";
import { connect } from "react-redux";

const Newsletter = ({
  subscriberCreate,
  subscriberLoading,
  subscriberError,
  subscriberSuccess,
}) => {
  let form = useRef();
  const [values, setValues] = useState({
    email: "",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.persist();
    subscriberCreate(values);
  };

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  useEffect(() => {
    if (subscriberSuccess) {
      form.reset();
    }
  }, [subscriberSuccess]);

  return (
    <Container>
      <div className="card-block dark">
        <Row className="justify-content-center my-5">
          <Col xs={10} lg={6} className="text-center">
            <Row>
              <Col xs={12} className="mb-4">
                <h4 className="mb-2">Претплатете се</h4>
                <p>
                  Претплатете се на нашиот е-билтен за да бидете ажурирани во
                  секој момент
                </p>
              </Col>
              <Col lg={12}>
                {subscriberError && showAlert(subscriberError, "danger")}
                {subscriberSuccess &&
                  showAlert(
                    "Успешно се пријавивте на нашиот е-билтен!",
                    "success"
                  )}
                <AvForm onValidSubmit={handleSubmit} ref={(f) => (form = f)}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <AvField
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Внеси е-маил..."
                          id="email"
                          onChange={handleChange("email")}
                          required
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Внесете валиден податок!",
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <Button
                        block
                        type="submit"
                        className="btn-custom secondary"
                      >
                        {!subscriberLoading ? (
                          "Пријави се"
                        ) : (
                          <Spinner children={false} size="sm" />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriberLoading: state.subscriber.subscriberLoading,
    subscriberError: state.subscriber.subscriberError,
    subscriberSuccess: state.subscriber.subscriberSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    subscriberCreate: (subscriberData) =>
      dispatch(actions.subscriberCreate(subscriberData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
