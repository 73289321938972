import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  const navigationmenu = [
    {
      id: 1,
      linkText: "Почетна",
      link: "/",
    },
    {
      id: 2,
      linkText: "Блог",
      link: "/блог",
    },
    {
      id: 3,
      linkText: "Прашања",
      link: "/прашања",
    },
    {
      id: 4,
      linkText: "Закажи термин",
      link: "/#закажи-термин",
    },
    {
      id: 5,
      linkText: "Контакт",
      link: "/контакт",
    },
  ];

  return (
    <ul className="navbar-nav d-none d-xl-flex">
      {navigationmenu.map((item, i) =>
        item.link ? (
          <li key={i} className="menu-item" onClick={item.onClick}>
            <Link to={item.link}> {item.linkText} </Link>
          </li>
        ) : (
          <div class="dropdown" key={i}>
            <li className="menu-item">{item.linkText}</li>
            <div class="dropdown-content">
              {item.links.map((item) => (
                <React.Fragment>
                  <Link to={item.link}>{item.linkText}</Link>
                </React.Fragment>
              ))}
            </div>
          </div>
        )
      )}
    </ul>
  );
};

export default Navigation;
