import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all orders
export const ordersFetchStart = () => {
  return {
    type: actionTypes.FETCH_ORDERS_START,
  };
};
export const ordersFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    orders: data,
  };
};
export const ordersFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_ORDERS_FAIL,
    error: error,
  };
};
export const ordersFetch = (userId, token) => {
  return (dispatch) => {
    dispatch(ordersFetchStart());
    fetch(`${API}/orders/${userId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(ordersFetchFail(data.error));
        } else {
          dispatch(ordersFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(ordersFetchFail(error));
      });
  };
};

//get order by ID
export const orderFetchStart = () => {
  return {
    type: actionTypes.FETCH_ORDER_START,
  };
};
export const orderFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_ORDER_SUCCESS,
    order: data,
  };
};
export const orderFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_ORDER_FAIL,
    error: error,
  };
};
export const orderFetch = (orderId, userId, token) => {
  return (dispatch) => {
    dispatch(orderFetchStart());
    fetch(`${API}/order/${orderId}/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(orderFetchFail(data.error));
        } else {
          dispatch(orderFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(orderFetchFail(error));
      });
  };
};

//get weekly orders
export const ordersWeeklyFetchStart = () => {
  return {
    type: actionTypes.FETCH_WEEKLY_ORDERS_START,
  };
};
export const ordersWeeklyFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_WEEKLY_ORDERS_SUCCESS,
    ordersWeekly: data,
  };
};
export const ordersWeeklyCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_WEEKLY_ORDERS_COUNT_SUCCESS,
    count: data,
  };
};
export const ordersWeeklyFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_WEEKLY_ORDERS_FAIL,
    error: error,
  };
};
export const ordersWeeklyFetch = (
  userId,
  token,
  weekDates,
  filters,
  limit,
  skip
) => {
  return (dispatch) => {
    dispatch(ordersWeeklyFetchStart());
    fetch(`${API}/orders/countWeekly/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ weekDates }),
    })
      .then((response) => response.json())
      .then((result) => {
        let ordersCount = result.count;
        fetch(`${API}/orders/listWeekly/${userId}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ weekDates, filters, limit, skip }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(ordersWeeklyFetchFail(data.error));
            } else {
              dispatch(ordersWeeklyFetchSuccess(data));
              dispatch(ordersWeeklyCountFetchSuccess(ordersCount));
            }
          })
          .catch((err) => {
            dispatch(ordersWeeklyFetchFail(err));
          });
      });
  };
};

//get monthly orders
export const ordersMonthlyFetchStart = () => {
  return {
    type: actionTypes.FETCH_MONTHLY_ORDERS_START,
  };
};
export const ordersMonthlyFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MONTHLY_ORDERS_SUCCESS,
    ordersMonthly: data,
  };
};
export const ordersMonthlyCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MONTHLY_ORDERS_COUNT_SUCCESS,
    count: data,
  };
};
export const ordersMonthlyFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_MONTHLY_ORDERS_FAIL,
    error: error,
  };
};
export const ordersMonthlyFetch = (
  userId,
  token,
  monthDates,
  configuration,
  filters,
  limit,
  skip
) => {
  return (dispatch) => {
    dispatch(ordersMonthlyFetchStart());
    fetch(`${API}/orders/countMonthly/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ monthDates }),
    })
      .then((response) => response.json())
      .then((result) => {
        let ordersCount = result.count;
        fetch(`${API}/orders/listMonthly/${userId}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            monthDates,
            configuration,
            filters,
            limit,
            skip,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(ordersMonthlyFetchFail(data.error));
            } else {
              dispatch(ordersMonthlyFetchSuccess(data));
              dispatch(ordersMonthlyCountFetchSuccess(ordersCount));
            }
          })
          .catch((error) => {
            dispatch(ordersMonthlyFetchFail(error));
          });
      });
  };
};

//create order
export const orderCreateStart = () => {
  return {
    type: actionTypes.CREATE_ORDER_START,
  };
};
export const orderCreateSuccess = (data) => {
  return {
    type: actionTypes.CREATE_ORDER_SUCCESS,
  };
};
export const orderCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_ORDER_FAIL,
    error: error,
  };
};
export const orderCreate = (userId, token, paymentInfo) => {
  return (dispatch) => {
    dispatch(orderCreateStart());
    fetch(`${API}/order/create/${userId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(paymentInfo),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(orderCreateFail(data.error));
        } else {
          dispatch(orderCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(orderCreateFail(error));
      });
  };
};
export const populateProducts = (products) => {
  return (dispatch) => {
    let populatedObjects = products.map((product, i) => {
      let option = product.option;
      return { ...product, option };
    });
    return populatedObjects;
  };
};

//update order status
export const orderStatusUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_START,
  };
};
export const orderStatusUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_SUCCESS,
    order: data,
  };
};
export const orderStatusUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_FAIL,
    error: error,
  };
};
export const orderStatusUpdate = (userId, token, orderId, status) => {
  return (dispatch) => {
    dispatch(orderStatusUpdateStart());
    fetch(`${API}/order/${orderId}/status/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status, orderId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(orderStatusUpdateFail(data.error));
        } else {
          dispatch(orderStatusUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(orderStatusUpdateFail(error));
      });
  };
};

//get status values
export const orderStatusesFetchStart = () => {
  return {
    type: actionTypes.FETCH_ORDER_STATUSES_START,
  };
};
export const orderStatusesFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_ORDER_STATUSES_SUCCESS,
    statuses: data,
  };
};
export const orderStatusesFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_ORDER_STATUSES_FAIL,
    error: error,
  };
};
export const orderStatusesFetch = (userId, token) => {
  return (dispatch) => {
    dispatch(orderStatusesFetchStart());
    fetch(`${API}/order/status-values/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(orderStatusesFetchFail(data.error));
        } else {
          dispatch(orderStatusesFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(orderStatusesFetchFail(error));
      });
  };
};

//clear order message
export const orderMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_ORDER_MESSAGE_SUCCESS,
  };
};
export const orderMessageClear = () => {
  return (dispatch) => {
    dispatch(orderMessageClearSuccess());
  };
};
