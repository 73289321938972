import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  subscriber: null,
  subscriberSuccess: null,
  subscriberError: null,
  subscriberLoading: false,

  subscribersFiltered: null,
  subscribersFilteredCount: null,
  subscribersFilteredSuccess: null,
  subscribersFilteredError: null,
  subscribersFilteredLoading: false,

  subscriberDeleteSuccess: null,
  subscriberDeleteError: null,
  subscriberDeleteLoading: false,
};

//get filtered subscribers
const subscribersFilteredFetchStart = (state) => {
  return updateObject(state, {
    subscribersFilteredSuccess: null,
    subscribersFilteredError: null,
    subscribersFilteredLoading: true,
  });
};
const subscribersFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    subscribersFiltered: action.subscribers,
    subscribersFilteredSuccess: true,
    subscribersFilteredError: null,
    subscribersFilteredLoading: false,
  });
};
const subscribersFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    subscribersFilteredCount: action.count,
  });
};
const subscribersFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    subscribersFilteredError: action.error,
    subscribersFilteredSuccess: false,
    subscribersFilteredLoading: false,
  });
};

//create subscriber
const subscriberCreateStart = (state) => {
  return updateObject(state, {
    subscriberSuccess: null,
    subscriberError: null,
    subscriberLoading: true,
  });
};
const subscriberCreateSuccess = (state, action) => {
  return updateObject(state, {
    subscriber: action.subscriber,
    subscriberSuccess: true,
    subscriberError: null,
    subscriberLoading: false,
  });
};
const subscriberCreateFail = (state, action) => {
  return updateObject(state, {
    subscriberError: action.error,
    subscriberSuccess: false,
    subscriberLoading: false,
  });
};

//delete subscriber
const subscriberDeleteStart = (state) => {
  return updateObject(state, {
    subscriberDeleteSuccess: null,
    subscriberDeleteError: null,
    subscriberDeleteLoading: true,
  });
};
const subscriberDeleteSuccess = (state, action) => {
  return updateObject(state, {
    subscriber: action.subscriber,
    subscriberDeleteSuccess: true,
    subscriberDeleteError: null,
    subscriberDeleteLoading: false,
  });
};
const subscriberDeleteFail = (state, action) => {
  return updateObject(state, {
    subscriberDeleteError: action.error,
    subscriberDeleteSuccess: false,
    subscriberDeleteLoading: false,
  });
};

const subscriberMessageClearSuccess = (state, action) => {
  return updateObject(state, {
    subscriberSuccess: null,
    subscriberError: null,
    subscribersFilteredSuccess: null,
    subscribersFilteredError: null,
    subscriberDeleteSuccess: null,
    subscriberDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FILTERED_SUBSCRIBERS_START:
      return subscribersFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_SUBSCRIBERS_SUCCESS:
      return subscribersFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_SUBSCRIBERS_COUNT_SUCCESS:
      return subscribersFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_SUBSCRIBERS_FAIL:
      return subscribersFilteredFetchFail(state, action);

    case actionTypes.CREATE_SUBSCRIBER_START:
      return subscriberCreateStart(state);
    case actionTypes.CREATE_SUBSCRIBER_SUCCESS:
      return subscriberCreateSuccess(state, action);
    case actionTypes.CREATE_SUBSCRIBER_FAIL:
      return subscriberCreateFail(state, action);

    case actionTypes.DELETE_SUBSCRIBER_START:
      return subscriberDeleteStart(state);
    case actionTypes.DELETE_SUBSCRIBER_SUCCESS:
      return subscriberDeleteSuccess(state, action);
    case actionTypes.DELETE_SUBSCRIBER_FAIL:
      return subscriberDeleteFail(state, action);

    case actionTypes.CLEAR_SUBSCRIBER_MESSAGE_SUCCESS:
      return subscriberMessageClearSuccess(state);
    default:
      return state;
  }
};
export default reducer;
