//category
export const FETCH_CATEGORY_START = "FETCH_CATEGORY_START";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORIES_COUNT_SUCCESS = "FETCH_CATEGORIES_COUNT_SUCCESS";
export const FETCH_CATEGORY_FAIL = "FETCH_CATEGORY_FAIL";

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAIL = "FETCH_CATEGORIES_FAIL";

export const FETCH_FILTERED_CATEGORIES_START =
  "FETCH_FILTERED_CATEGORIES_START";
export const FETCH_FILTERED_CATEGORIES_SUCCESS =
  "FETCH_FILTERED_CATEGORIES_SUCCESS";
export const FETCH_FILTERED_CATEGORIES_COUNT_SUCCESS =
  "FETCH_FILTERED_CATEGORIES_COUNT_SUCCESS";
export const FETCH_FILTERED_CATEGORIES_FAIL = "FETCH_FILTERED_CATEGORIES_FAIL";

export const CREATE_CATEGORY_START = "CREATE_CATEGORY_START";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";

export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const DELETE_CATEGORY_START = "DELETE_CATEGORY_START";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const CLEAR_CATEGORY_MESSAGE_SUCCESS = "CLEAR_CATEGORY_MESSAGE_SUCCESS";

//subcategory
export const FETCH_SUBCATEGORY_START = "FETCH_SUBCATEGORY_START";
export const FETCH_SUBCATEGORY_SUCCESS = "FETCH_SUBCATEGORY_SUCCESS";
export const FETCH_SUBCATEGORY_FAIL = "FETCH_SUBCATEGORY_FAIL";

export const FETCH_SUBCATEGORIES_START = "FETCH_SUBCATEGORIES_START";
export const FETCH_SUBCATEGORIES_SUCCESS = "FETCH_SUBCATEGORIES_SUCCESS";
export const FETCH_SUBCATEGORIES_FAIL = "FETCH_SUBCATEGORIES_FAIL";
export const FETCH_SUBCATEGORIES_COUNT_SUCCESS =
  "FETCH_SUBCATEGORIES_COUNT_SUCCESS";

export const FETCH_FILTERED_SUBCATEGORIES_START =
  "FETCH_FILTERED_SUBCATEGORIES_START";
export const FETCH_FILTERED_SUBCATEGORIES_SUCCESS =
  "FETCH_FILTERED_SUBCATEGORIES_SUCCESS";
export const FETCH_FILTERED_SUBCATEGORIES_COUNT_SUCCESS =
  "FETCH_FILTERED_SUBCATEGORIES_COUNT_SUCCESS";
export const FETCH_FILTERED_SUBCATEGORIES_FAIL =
  "FETCH_FILTERED_SUBCATEGORIES_FAIL";

export const CREATE_SUBCATEGORY_START = "CREATE_SUBCATEGORY_START";
export const CREATE_SUBCATEGORY_SUCCESS = "CREATE_SUBCATEGORY_SUCCESS";
export const CREATE_SUBCATEGORY_FAIL = "CREATE_SUBCATEGORY_FAIL";

export const UPDATE_SUBCATEGORY_START = "UPDATE_SUBCATEGORY_START";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAIL = "UPDATE_SUBCATEGORY_FAIL";

export const DELETE_SUBCATEGORY_START = "DELETE_SUBCATEGORY_START";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAIL = "DELETE_SUBCATEGORY_FAIL";

export const CLEAR_SUBCATEGORY_MESSAGE_SUCCESS =
  "CLEAR_SUBCATEGORY_MESSAGE_SUCCESS";

//question
export const FETCH_QUESTION_START = "FETCH_QUESTION_START";
export const FETCH_QUESTION_SUCCESS = "FETCH_QUESTION_SUCCESS";
export const FETCH_QUESTION_FAIL = "FETCH_QUESTION_FAIL";

export const FETCH_QUESTIONS_START = "FETCH_QUESTIONS_START";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const FETCH_QUESTIONS_FAIL = "FETCH_QUESTIONS_FAIL";

export const FETCH_FILTERED_QUESTIONS_START = "FETCH_FILTERED_QUESTIONS_START";
export const FETCH_FILTERED_QUESTIONS_SUCCESS =
  "FETCH_FILTERED_QUESTIONS_SUCCESS";
export const FETCH_FILTERED_QUESTIONS_FAIL = "FETCH_FILTERED_QUESTIONS_FAIL";
export const FETCH_FILTERED_QUESTIONS_COUNT_SUCCESS =
  "FETCH_FILTERED_QUESTIONS_COUNT_SUCCESS";

export const CREATE_QUESTION_START = "CREATE_QUESTION_START";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_FAIL = "CREATE_QUESTION_FAIL";

export const UPDATE_QUESTION_START = "UPDATE_QUESTION_START";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_FAIL = "UPDATE_QUESTION_FAIL";

export const DELETE_QUESTION_START = "DELETE_QUESTION_START";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL";

export const CLEAR_QUESTION_MESSAGE_SUCCESS = "CLEAR_QUESTION_MESSAGE_SUCCESS";

//user
export const SIGNIN_USER_START = "SIGNIN_USER_START";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_FAIL = "SIGNIN_USER_FAIL";

export const SIGNOUT_USER_START = "SIGNOUT_USER_START";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const SIGNOUT_USER_FAIL = "SIGNOUT_USER_FAIL";

export const SIGNUP_USER_START = "SIGNUP_USER_START";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAIL = "SIGNUP_USER_FAIL";

export const ACTIVATE_USER_START = "ACTIVATE_USER_START";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";

export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const FETCH_FILTERED_USERS_START = "FETCH_FILTERED_USERS_START";
export const FETCH_FILTERED_USERS_SUCCESS = "FETCH_FILTERED_USERS_SUCCESS";
export const FETCH_FILTERED_USERS_COUNT_SUCCESS =
  "FETCH_FILTERED_USERS_COUNT_SUSCESS";
export const FETCH_FILTERED_USERS_FAIL = "FETCH_FILTERED_USERS_FAIL";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const UPDATE_USER_PASSWORD_START = "UPDATE_USER_PASSWORD_START";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";

export const CLEAR_USER_MESSAGE_SUCCESS = "CLEAR_USER_MESSAGE_SUCCESS";

export const CLEAR_AUTHENTICATION_MESSAGE_SUCCESS =
  "CLEAR_AUTHENTICATION_MESSAGE_SUCCESS";

//contact
export const SEND_MAIL_START = "SEND_MAIL_START";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL";

export const SEND_USER_ACTIVATION_MAIL_START =
  "SEND_USER_ACTIVATION_MAIL_START";
export const SEND_USER_ACTIVATION_MAIL_SUCCESS =
  "SEND_USER_ACTIVATION_MAIL_SUCCESS";
export const SEND_USER_ACTIVATION_MAIL_FAIL = "SEND_USER_ACTIVATION_MAIL_FAIL";

export const SEND_ORDER_MAIL_START = "SEND_ORDER_MAIL_START";
export const SEND_ORDER_MAIL_SUCCESS = "SEND_ORDER_MAIL_SUCCESS";
export const SEND_ORDER_MAIL_FAIL = "SEND_ORDER_MAIL_FAIL";

export const SEND_FORGOT_PASSWORD_MAIL_START =
  "SEND_FORGOT_PASSWORD_MAIL_START";
export const SEND_FORGOT_PASSWORD_MAIL_SUCCESS =
  "SEND_FORGOT_PASSWORD_MAIL_SUCCESS";
export const SEND_FORGOT_PASSWORD_MAIL_FAIL = "SEND_FORGOT_PASSWORD_MAIL_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const VALIDATE_RESET_TOKEN_START = "VALIDATE_RESET_TOKEN_START";
export const VALIDATE_RESET_TOKEN_SUCCESS = "VALIDATE_RESET_TOKEN_SUCCESS";
export const VALIDATE_RESET_TOKEN_FAIL = "VALIDATE_RESET_TOKEN_FAIL";

export const CLEAR_CONTACT_MESSAGE_SUCCESS = "CLEAR_CONTACT_MESSAGE_SUCCESS";

//subscriber
export const FETCH_FILTERED_SUBSCRIBERS_START =
  "FETCH_FILTERED_SUBSCRIBERS_START";
export const FETCH_FILTERED_SUBSCRIBERS_SUCCESS =
  "FETCH_FILTERED_SUBSCRIBERS_SUCCESS";
export const FETCH_FILTERED_SUBSCRIBERS_COUNT_SUCCESS =
  "FETCH_FILTERED_SUBSCRIBERS_COUNT_SUCCESS";
export const FETCH_FILTERED_SUBSCRIBERS_FAIL =
  "FETCH_FILTERED_SUBSCRIBERS_FAIL";

export const CREATE_SUBSCRIBER_START = "CREATE_SUBSCRIBER_START";
export const CREATE_SUBSCRIBER_SUCCESS = "CREATE_SUBSCRIBER_SUCCESS";
export const CREATE_SUBSCRIBER_FAIL = "CREATE_SUBSCRIBER_FAIL";

export const DELETE_SUBSCRIBER_START = "DELETE_SUBSCRIBER_START";
export const DELETE_SUBSCRIBER_SUCCESS = "DELETE_SUBSCRIBER_SUCCESS";
export const DELETE_SUBSCRIBER_FAIL = "DELETE_SUBSCRIBER_FAIL";

export const CLEAR_SUBSCRIBER_MESSAGE_SUCCESS =
  "CLEAR_SUBSCRIBER_MESSAGE_SUCCESS";

//mainBanners
export const FETCH_MAIN_BANNER_START = "FETCH_MAIN_BANNER_START";
export const FETCH_MAIN_BANNER_SUCCESS = "FETCH_MAIN_BANNER_SUCCESS";
export const FETCH_MAIN_BANNER_FAIL = "FETCH_MAIN_BANNER_START";

export const FETCH_FILTERED_MAIN_BANNERS_START =
  "FETCH_FILTERED_MAIN_BANNERS_START";
export const FETCH_FILTERED_MAIN_BANNERS_SUCCESS =
  "FETCH_FILTERED_MAIN_BANNERS_SUCCESS";
export const FETCH_FILTERED_MAIN_BANNERS_COUNT_SUCCESS =
  "FETCH_FILTERED_MAIN_BANNERS_COUNT_SUCCESS";
export const FETCH_FILTERED_MAIN_BANNERS_FAIL =
  "FETCH_FILTERED_MAIN_BANNERS_START";

export const CREATE_MAIN_BANNER_START = "CREATE_MAIN_BANNER_START";
export const CREATE_MAIN_BANNER_SUCCESS = "CREATE_MAIN_BANNER_SUCCESS";
export const CREATE_MAIN_BANNER_FAIL = "CREATE_MAIN_BANNER_FAIL";

export const UPDATE_MAIN_BANNER_START = "UPDATE_MAIN_BANNER_START";
export const UPDATE_MAIN_BANNER_SUCCESS = "UPDATE_MAIN_BANNER_SUCCESS";
export const UPDATE_MAIN_BANNER_FAIL = "UPDATE_MAIN_BANNER_FAIL";

export const DELETE_MAIN_BANNER_START = "DELETE_MAIN_BANNER_START";
export const DELETE_MAIN_BANNER_SUCCESS = "DELETE_MAIN_BANNER_SUCCESS";
export const DELETE_MAIN_BANNER_FAIL = "DELETE_MAIN_BANNER_FAIL";

export const CLEAR_MAIN_BANNER_MESSAGE_SUCCESS =
  "CLEAR_MAIN_BANNER_MESSAGE_SUCCESS";

//blog category
export const FETCH_BLOG_CATEGORY_START = "FETCH_BLOG_CATEGORY_START";
export const FETCH_BLOG_CATEGORY_SUCCESS = "FETCH_BLOG_CATEGORY_SUCCESS";
export const FETCH_BLOG_CATEGORIES_COUNT_SUCCESS =
  "FETCH_BLOG_CATEGORIES_COUNT_SUCCESS";
export const FETCH_BLOG_CATEGORY_FAIL = "FETCH_BLOG_CATEGORY_FAIL";

export const FETCH_BLOG_CATEGORIES_START = "FETCH_BLOG_CATEGORIES_START";
export const FETCH_BLOG_CATEGORIES_SUCCESS = "FETCH_BLOG_CATEGORIES_SUCCESS";
export const FETCH_BLOG_CATEGORIES_FAIL = "FETCH_BLOG_CATEGORIES_FAIL";

export const FETCH_BLOG_CATEGORIES_FILTERED_START =
  "FETCH_BLOG_CATEGORIES_FILTERED_START";
export const FETCH_BLOG_CATEGORIES_FILTERED_SUCCESS =
  "FETCH_BLOG_CATEGORIES_FILTERED_SUCCESS";
export const FETCH_BLOG_CATEGORIES_FILTERED_COUNT_SUCCESS =
  "FETCH_BLOG_CATEGORIES_FILTERED_COUNT_SUCCESS";
export const FETCH_BLOG_CATEGORIES_FILTERED_FAIL =
  "FETCH_BLOG_CATEGORIES_FILTERED_FAIL";

export const CREATE_BLOG_CATEGORY_START = "CREATE_BLOG_CATEGORY_START";
export const CREATE_BLOG_CATEGORY_SUCCESS = "CREATE_BLOG_CATEGORY_SUCCESS";
export const CREATE_BLOG_CATEGORY_FAIL = "CREATE_BLOG_CATEGORY_FAIL";

export const UPDATE_BLOG_CATEGORY_START = "UPDATE_BLOG_CATEGORY_START";
export const UPDATE_BLOG_CATEGORY_SUCCESS = "UPDATE_BLOG_CATEGORY_SUCCESS";
export const UPDATE_BLOG_CATEGORY_FAIL = "UPDATE_BLOG_CATEGORY_FAIL";

export const DELETE_BLOG_CATEGORY_START = "DELETE_BLOG_CATEGORY_START";
export const DELETE_BLOG_CATEGORY_SUCCESS = "DELETE_BLOG_CATEGORY_SUCCESS";
export const DELETE_BLOG_CATEGORY_FAIL = "DELETE_BLOG_CATEGORY_FAIL";

export const CLEAR_BLOG_CATEGORY_MESSAGE_SUCCESS =
  "CLEAR_BLOG_CATEGORY_MESSAGE_SUCCESS";

//blog posts
export const FETCH_BLOG_POST_START = "FETCH_BLOG_POST_START";
export const FETCH_BLOG_POST_SUCCESS = "FETCH_BLOG_POST_SUCCESS";
export const FETCH_BLOG_POST_FAIL = "FETCH_BLOG_POST_FAIL";

export const FETCH_BLOG_POSTS_START = "FETCH_BLOG_POSTS_START";
export const FETCH_BLOG_POSTS_SUCCESS = "FETCH_BLOG_POSTS_SUCCESS";
export const FETCH_BLOG_POSTS_FAIL = "FETCH_BLOG_POSTS_FAIL";

export const FETCH_RELATED_BLOG_POSTS_START = "FETCH_RELATED_BLOG_POSTS_START";
export const FETCH_RELATED_BLOG_POSTS_SUCCESS =
  "FETCH_RELATED_BLOG_POSTS_SUCCESS";
export const FETCH_RELATED_BLOG_POSTS_FAIL = "FETCH_RELATED_BLOG_POSTS_FAIL";

export const FETCH_FILTERED_BLOG_POSTS_START =
  "FETCH_FILTERED_BLOG_POSTS_START";
export const FETCH_FILTERED_BLOG_POSTS_SUCCESS =
  "FETCH_FILTERED_BLOG_POSTS_SUCCESS";
export const FETCH_FILTERED_BLOG_POSTS_COUNT_SUCCESS =
  "FETCH_FILTERED_BLOG_POSTS_COUNT_SUCCESS";
export const FETCH_FILTERED_BLOG_POSTS_FAIL = "FETCH_FILTERED_BLOG_POSTS_FAIL";

export const CREATE_BLOG_POST_START = "CREATE_BLOG_POST_START";
export const CREATE_BLOG_POST_SUCCESS = "CREATE_BLOG_POST_SUCCESS";
export const CREATE_BLOG_POST_FAIL = "CREATE_BLOG_POST_FAIL";

export const UPDATE_BLOG_POST_START = "UPDATE_BLOG_POST_START";
export const UPDATE_BLOG_POST_SUCCESS = "UPDATE_BLOG_POST_SUCCESS";
export const UPDATE_BLOG_POST_FAIL = "UPDATE_BLOG_POST_FAIL";

export const DELETE_BLOG_POST_START = "DELETE_BLOG_POST_START";
export const DELETE_BLOG_POST_SUCCESS = "DELETE_BLOG_POST_SUCCESS";
export const DELETE_BLOG_POST_FAIL = "DELETE_BLOG_POST_FAIL";

export const CLEAR_BLOG_POST_MESSAGE_SUCCESS =
  "CLEAR_BLOG_POST_MESSAGE_SUCCESS";

//image
export const FETCH_FILTERED_IMAGES_START = "FETCH_FILTERED_IMAGES_START";
export const FETCH_FILTERED_IMAGES_SUCCESS = "FETCH_FILTERED_IMAGES_SUCCESS";
export const FETCH_FILTERED_IMAGES_COUNT_SUCCESS =
  "FETCH_FILTERED_IMAGES_COUNT_SUCCESS";
export const FETCH_FILTERED_IMAGES_FAIL = "FETCH_FILTERED_IMAGES_FAIL";

export const UPLOAD_IMAGE_START = "UPLOAD_IMAGE_START";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";

export const DELETE_IMAGE_START = "DELETE_IMAGE_START";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAIL = "DELETE_IMAGE_FAIL";

export const CLEAR_UPLOAD_IMAGE_MESSAGE_SUCCESS =
  "CLEAR_UPLOAD_IMAGE_MESSAGE_SUCCESS";

//order
export const FETCH_ORDER_START = "FETCH_ORDER_START";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAIL = "FETCH_ORDER_FAIL";

export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const FETCH_WEEKLY_ORDERS_START = "FETCH_WEEKLY_ORDERS_START";
export const FETCH_WEEKLY_ORDERS_SUCCESS = "FETCH_WEEKLY_ORDERS_SUCCESS";
export const FETCH_WEEKLY_ORDERS_COUNT_SUCCESS =
  "FETCH_WEEKLY_ORDERS_COUNT_SUCCESS";
export const FETCH_WEEKLY_ORDERS_FAIL = "FETCH_WEEKLY_ORDERS_FAIL";

export const FETCH_MONTHLY_ORDERS_START = "FETCH_MONTHLY_ORDERS_START";
export const FETCH_MONTHLY_ORDERS_SUCCESS = "FETCH_MONTHLY_ORDERS_SUCCESS";
export const FETCH_MONTHLY_ORDERS_COUNT_SUCCESS =
  "FETCH_MONTHLY_ORDERS_COUNT_SUCCESS";
export const FETCH_MONTHLY_ORDERS_FAIL = "FETCH_MONTHLY_ORDERS_FAIL";

export const CREATE_ORDER_START = "CREATE_ORDER_START";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";

export const FETCH_ORDER_STATUSES_START = "FETCH_ORDER_STATUSES_START";
export const FETCH_ORDER_STATUSES_SUCCESS = "FETCH_ORDER_STATUSES_SUCCESS";
export const FETCH_ORDER_STATUSES_FAIL = "FETCH_ORDER_STATUSES_FAIL";

export const UPDATE_ORDER_STATUS_START = "UPDATE_ORDER_STATUS_START";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAIL = "UPDATE_ORDER_STATUS_FAIL";

export const CLEAR_ORDER_MESSAGE_SUCCESS = "CLEAR_ORDER_MESSAGE_SUCCESS";

//package
export const FETCH_PACKAGE_START = "FETCH_PACKAGE_START";
export const FETCH_PACKAGE_SUCCESS = "FETCH_PACKAGE_SUCCESS";
export const FETCH_PACKAGE_FAIL = "FETCH_PACKAGE_FAIL";

export const FETCH_PACKAGES_START = "FETCH_PACKAGES_START";
export const FETCH_PACKAGES_SUCCESS = "FETCH_PACKAGES_SUCCESS";
export const FETCH_PACKAGES_FAIL = "FETCH_PACKAGES_FAIL";

export const FETCH_PACKAGES_FILTERED_START = "FETCH_PACKAGES_FILTERED_START";
export const FETCH_PACKAGES_FILTERED_SUCCESS =
  "FETCH_PACKAGES_FILTERED_SUCCESS";
export const FETCH_PACKAGES_FILTERED_COUNT_SUCCESS =
  "FETCH_PACKAGES_FILTERED_COUNT_SUCCESS";
export const FETCH_PACKAGES_FILTERED_FAIL = "FETCH_PACKAGES_FILTERED_FAIL";

export const CREATE_PACKAGE_START = "CREATE_PACKAGE_START";
export const CREATE_PACKAGE_SUCCESS = "CREATE_PACKAGE_SUCCESS";
export const CREATE_PACKAGE_FAIL = "CREATE_PACKAGE_FAIL";

export const UPDATE_PACKAGE_START = "UPDATE_PACKAGE_START";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_FAIL = "UPDATE_PACKAGE_FAIL";

export const DELETE_PACKAGE_START = "DELETE_PACKAGE_START";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAIL = "DELETE_PACKAGE_FAIL";

export const CLEAR_PACKAGE_MESSAGE_SUCCESS = "CLEAR_PACKAGE_MESSAGE_SUCCESS";

//term
export const FETCH_TERM_START = "FETCH_TERM_START";
export const FETCH_TERM_SUCCESS = "FETCH_TERM_SUCCESS";
export const FETCH_TERM_FAIL = "FETCH_TERM_FAIL";

export const FETCH_TERMS_START = "FETCH_TERMS_START";
export const FETCH_TERMS_SUCCESS = "FETCH_TERMS_SUCCESS";
export const FETCH_TERMS_COUNT_SUCCESS = "FETCH_TERMS_COUNT_SUCCESS";
export const FETCH_TERMS_FAIL = "FETCH_TERMS_FAIL";

export const FETCH_FILTERED_TERMS_START = "FETCH_FILTERED_TERMS_START";
export const FETCH_FILTERED_TERMS_SUCCESS = "FETCH_FILTERED_TERMS_SUCCESS";
export const FETCH_FILTERED_TERMS_COUNT_SUCCESS =
  "FETCH_FILTERED_TERMS_COUNT_SUSCESS";
export const FETCH_FILTERED_TERMS_FAIL = "FETCH_FILTERED_TERMS_FAIL";

export const CREATE_TERM_START = "CREATE_TERM_START";
export const CREATE_TERM_SUCCESS = "CREATE_TERM_SUCCESS";
export const CREATE_TERM_FAIL = "CREATE_TERM_FAIL";

export const UPDATE_TERM_START = "UPDATE_TERM_START";
export const UPDATE_TERM_SUCCESS = "UPDATE_TERM_SUCCESS";
export const UPDATE_TERM_FAIL = "UPDATE_TERM_FAIL";

export const DELETE_TERM_START = "DELETE_TERM_START";
export const DELETE_TERM_SUCCESS = "DELETE_TERM_SUCCESS";
export const DELETE_TERM_FAIL = "DELETE_TERM_FAIL";

export const CLEAR_TERM_MESSAGE_SUCCESS = "CLEAR_TERM_MESSAGE_SUCCESS";
