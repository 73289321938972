import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  term: null,
  termSuccess: null,
  termError: null,
  termLoading: false,

  terms: null,
  termsCount: null,
  termsSuccess: null,
  termsError: null,
  termsLoading: false,

  termsFiltered: null,
  termsFilteredCount: null,
  termsFilteredSuccess: null,
  termsFilteredError: null,
  termsFilteredLoading: false,

  termUpdateSuccess: null,
  termUpdateError: null,
  termUpdateLoading: false,

  termDeleteSuccess: null,
  termDeleteError: null,
  termDeleteLoading: false,
};

//get all terms
const termsFetchStart = (state) => {
  return updateObject(state, {
    termsSuccess: null,
    termsError: null,
    termsLoading: true,
  });
};
const termsFetchSuccess = (state, action) => {
  return updateObject(state, {
    terms: action.terms,
    termsSuccess: true,
    termsError: null,
    termsLoading: false,
  });
};
const termsCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    termsCount: action.count,
  });
};
const termsFetchFail = (state, action) => {
  return updateObject(state, {
    termsError: action.error,
    termsSuccess: false,
    termsLoading: false,
  });
};

//get filtered terms
const termsFilteredFetchStart = (state) => {
  return updateObject(state, {
    termsFilteredSuccess: null,
    termsFilteredError: null,
    termsFilteredLoading: true,
  });
};
const termsFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    termsFiltered: action.terms,
    termsFilteredSuccess: true,
    termsFilteredError: null,
    termsFilteredLoading: false,
  });
};
const termsFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    termsFilteredCount: action.count,
  });
};
const termsFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    termsFilteredError: action.error,
    termsFilteredSuccess: false,
    termsFilteredLoading: false,
  });
};

//get term by Name
const termFetchStart = (state) => {
  return updateObject(state, {
    termSuccess: null,
    termError: null,
    termLoading: true,
  });
};
const termFetchSuccess = (state, action) => {
  return updateObject(state, {
    term: action.term,
    termSuccess: true,
    termError: null,
    termLoading: false,
  });
};
const termFetchFail = (state, action) => {
  return updateObject(state, {
    termError: action.error,
    termSuccess: false,
    termLoading: false,
  });
};

//create term
const termCreateStart = (state) => {
  return updateObject(state, {
    termSuccess: null,
    termError: null,
    termLoading: true,
  });
};
const termCreateSuccess = (state, action) => {
  return updateObject(state, {
    term: action.term,
    termSuccess: true,
    termError: null,
    termLoading: false,
  });
};
const termCreateFail = (state, action) => {
  return updateObject(state, {
    termError: action.error,
    termSuccess: false,
    termLoading: false,
  });
};

//update term
const termUpdateStart = (state) => {
  return updateObject(state, {
    termUpdateSuccess: null,
    termUpdateError: null,
    termUpdateLoading: true,
  });
};
const termUpdateSuccess = (state, action) => {
  return updateObject(state, {
    term: action.term,
    termUpdateSuccess: true,
    termUpdateError: null,
    termUpdateLoading: false,
  });
};
const termUpdateFail = (state, action) => {
  return updateObject(state, {
    termUpdateError: action.error,
    termUpdateSuccess: false,
    termUpdateLoading: false,
  });
};

//delete term
const termDeleteStart = (state) => {
  return updateObject(state, {
    termDeleteSuccess: null,
    termDeleteError: null,
    termDeleteLoading: true,
  });
};
const termDeleteSuccess = (state, action) => {
  return updateObject(state, {
    term: action.term,
    termDeleteSuccess: true,
    termDeleteError: null,
    termDeleteLoading: false,
  });
};
const termDeleteFail = (state, action) => {
  return updateObject(state, {
    termDeleteError: action.error,
    termDeleteSuccess: false,
    termDeleteLoading: false,
  });
};

//clear term message
const termMessageClearSuccess = (state) => {
  return updateObject(state, {
    termSuccess: null,
    termError: null,
    termsSuccess: null,
    termsError: null,
    termUpdateSuccess: null,
    termUpdateError: null,
    termDeleteSuccess: null,
    termDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TERMS_START:
      return termsFetchStart(state);
    case actionTypes.FETCH_TERMS_SUCCESS:
      return termsFetchSuccess(state, action);
    case actionTypes.FETCH_TERMS_COUNT_SUCCESS:
      return termsCountFetchSuccess(state, action);
    case actionTypes.FETCH_TERMS_FAIL:
      return termsFetchFail(state, action);

    case actionTypes.FETCH_FILTERED_TERMS_START:
      return termsFilteredFetchStart(state);
    case actionTypes.FETCH_FILTERED_TERMS_SUCCESS:
      return termsFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_TERMS_COUNT_SUCCESS:
      return termsFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_FILTERED_TERMS_FAIL:
      return termsFilteredFetchFail(state, action);

    case actionTypes.FETCH_TERM_START:
      return termFetchStart(state);
    case actionTypes.FETCH_TERM_SUCCESS:
      return termFetchSuccess(state, action);
    case actionTypes.FETCH_TERM_FAIL:
      return termFetchFail(state, action);

    case actionTypes.CREATE_TERM_START:
      return termCreateStart(state);
    case actionTypes.CREATE_TERM_SUCCESS:
      return termCreateSuccess(state, action);
    case actionTypes.CREATE_TERM_FAIL:
      return termCreateFail(state, action);

    case actionTypes.UPDATE_TERM_START:
      return termUpdateStart(state);
    case actionTypes.UPDATE_TERM_SUCCESS:
      return termUpdateSuccess(state, action);
    case actionTypes.UPDATE_TERM_FAIL:
      return termUpdateFail(state, action);

    case actionTypes.DELETE_TERM_START:
      return termDeleteStart(state);
    case actionTypes.DELETE_TERM_SUCCESS:
      return termDeleteSuccess(state, action);
    case actionTypes.DELETE_TERM_FAIL:
      return termDeleteFail(state, action);

    case actionTypes.CLEAR_TERM_MESSAGE_SUCCESS:
      return termMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
