import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  mailError: null,
  mailSuccess: null,
  mailLoading: false,

  passwordResetError: null,
  passwordResetSuccess: null,
  passwordResetLoading: false,

  resetTokenValidateError: null,
  resetTokenValidateSuccess: null,
  resetTokenValidateLoading: false,
};

//send mail
const mailSendStart = (state) => {
  return updateObject(state, {
    mailSuccess: null,
    mailError: null,
    mailLoading: true,
  });
};
const mailSendSuccess = (state, action) => {
  return updateObject(state, {
    mailSuccess: true,
    mailError: null,
    mailLoading: false,
  });
};
const mailSendFail = (state, action) => {
  return updateObject(state, {
    mailError: action.error,
    mailSuccess: false,
    mailLoading: false,
  });
};

//send user activation mail
const userActivationMailSendStart = (state) => {
  return updateObject(state, {
    mailSuccess: null,
    mailError: null,
    mailLoading: true,
  });
};
const userActivationMailSendSuccess = (state, action) => {
  return updateObject(state, {
    mailSuccess: true,
    mailError: null,
    mailLoading: false,
  });
};
const userActivationMailSendFail = (state, action) => {
  return updateObject(state, {
    mailError: action.error,
    mailSuccess: false,
    mailLoading: false,
  });
};

//send order mail
const mailOrderSendStart = (state) => {
  return updateObject(state, {
    mailSuccess: false,
    mailError: null,
    mailLoading: true,
  });
};
const mailOrderSendSuccess = (state, action) => {
  return updateObject(state, {
    mailSuccess: true,
    mailError: null,
    mailLoading: false,
  });
};
const mailOrderSendFail = (state, action) => {
  return updateObject(state, {
    mailError: action.error,
    mailSuccess: false,
    mailLoading: false,
  });
};

//send forgot password email
const mailSendForgotPasswordStart = (state) => {
  return updateObject(state, {
    mailSuccess: false,
    mailError: null,
    mailLoading: true,
  });
};
const mailSendForgotPasswordSuccess = (state, action) => {
  return updateObject(state, {
    mailSuccess: true,
    mailError: null,
    mailLoading: false,
  });
};
const mailSendForgotPasswordFail = (state, action) => {
  return updateObject(state, {
    mailError: action.error,
    mailSuccess: false,
    mailLoading: false,
  });
};

//reset password
const passwordResetStart = (state) => {
  return updateObject(state, {
    passwordResetSuccess: false,
    passwordResetError: null,
    passwordResetLoading: true,
  });
};
const passwordResetSuccess = (state, action) => {
  return updateObject(state, {
    passwordResetSuccess: true,
    passwordResetError: null,
    passwordResetLoading: false,
  });
};
const passwordResetFail = (state, action) => {
  return updateObject(state, {
    passwordResetError: action.error,
    passwordResetSuccess: false,
    passwordResetLoading: false,
  });
};

//reset token validation
const resetTokenValidateStart = (state) => {
  return updateObject(state, {
    resetTokenValidateSuccess: null,
    resetTokenValidateLoading: true,
    resetTokenValidateError: null,
  });
};
const resetTokenValidateSuccess = (state) => {
  return updateObject(state, {
    resetTokenValidateSuccess: true,
    resetTokenValidateLoading: false,
    resetTokenValidateError: null,
  });
};
const resetTokenValidateFail = (state, action) => {
  return updateObject(state, {
    resetTokenValidateError: action.error,
    resetTokenValidateSuccess: false,
    resetTokenValidateLoading: false,
  });
};

//clear mail message
const contactMessageClearSuccess = (state) => {
  return updateObject(state, {
    mailError: null,
    mailSuccess: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_MAIL_START:
      return mailSendStart(state);
    case actionTypes.SEND_MAIL_SUCCESS:
      return mailSendSuccess(state, action);
    case actionTypes.SEND_MAIL_FAIL:
      return mailSendFail(state, action);

    case actionTypes.SEND_USER_ACTIVATION_MAIL_START:
      return userActivationMailSendStart(state);
    case actionTypes.SEND_USER_ACTIVATION_MAIL_SUCCESS:
      return userActivationMailSendSuccess(state, action);
    case actionTypes.SEND_USER_ACTIVATION_MAIL_FAIL:
      return userActivationMailSendFail(state, action);

    case actionTypes.SEND_ORDER_MAIL_START:
      return mailOrderSendStart(state);
    case actionTypes.SEND_ORDER_MAIL_SUCCESS:
      return mailOrderSendSuccess(state, action);
    case actionTypes.SEND_ORDER_MAIL_FAIL:
      return mailOrderSendFail(state, action);

    case actionTypes.SEND_FORGOT_PASSWORD_MAIL_START:
      return mailSendForgotPasswordStart(state);
    case actionTypes.SEND_FORGOT_PASSWORD_MAIL_SUCCESS:
      return mailSendForgotPasswordSuccess(state, action);
    case actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL:
      return mailSendForgotPasswordFail(state, action);

    case actionTypes.RESET_PASSWORD_START:
      return passwordResetStart(state);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return passwordResetSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return passwordResetFail(state, action);

    case actionTypes.VALIDATE_RESET_TOKEN_START:
      return resetTokenValidateStart(state);
    case actionTypes.VALIDATE_RESET_TOKEN_SUCCESS:
      return resetTokenValidateSuccess(state, action);
    case actionTypes.VALIDATE_RESET_TOKEN_FAIL:
      return resetTokenValidateFail(state, action);

    case actionTypes.CLEAR_CONTACT_MESSAGE_SUCCESS:
      return contactMessageClearSuccess(state);
    default:
      return state;
  }
};
export default reducer;
