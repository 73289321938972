import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "reactstrap";
import classnames from "classnames";
import Calendar from "react-calendar";
import moment from "moment";
import Loader from "../../../components/layouts/Loader";
import "react-calendar/dist/Calendar.css";

const Step4 = ({
  values,
  termsFilteredFetch,
  termsFiltered,
  termsFilteredLoading,
  handleClick,
  next,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [termsArr, setTermsArr] = useState([]);
  const [totalRemainingTime, setTotalRemainingTime] = useState();
  const [activeItem, setActiveItem] = useState(null);
  const [todaysDate] = useState(new Date());
  const [tomorrowsDate, setTomorrowsDate] = useState();

  const handleItemClick = (item, key) => {
    if (item.scheduled) {
      return;
    }
    setActiveItem(key);
    next();
  };

  useEffect(() => {
    if (values.package && todaysDate) {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      setTomorrowsDate(tomorrow);
      setTotalRemainingTime();
      setTermsArr([]);
      setSelectedDate(tomorrow);
    }
  }, [values.package, todaysDate]);

  useEffect(() => {
    if (selectedDate) {
      let date = new Date(selectedDate);
      setActiveItem(null);
      setTotalRemainingTime();
      termsFilteredFetch(0, 50, {
        startDate:
          date.getUTCFullYear() +
          "-0" +
          (+date.getMonth() + 1) +
          "-0" +
          date.getDate(),
        endDate:
          date.getUTCFullYear() +
          "-0" +
          (+date.getMonth() + 1) +
          "-0" +
          +date.getDate(),
      });
    }
  }, [selectedDate, termsFilteredFetch]);

  useEffect(() => {
    if (termsFiltered) {
      let oldArr = [];
      let totalScheduledTime = 0;
      termsFiltered.map((term) => {
        let diff = Math.floor(
          moment(term.endDate).diff(moment(term.startDate)) / 60000
        );
        totalScheduledTime += diff;
        oldArr.push({
          startDate: moment(term.startDate).toDate(),
          endDate: moment(term.endDate).toDate(),
          scheduled: true,
        });
      });
      setTotalRemainingTime(480 - totalScheduledTime);
      setTermsArr(oldArr);
    }
  }, [termsFiltered]);

  useEffect(() => {
    if (totalRemainingTime > values.package.duration) {
      let terms = [...termsArr];
      let termStart = moment(selectedDate);
      let remainingTime = totalRemainingTime;
      termStart.hour(9);
      termStart.minute(0);
      termStart.second(0);
      termStart.millisecond(0);
      termsArr.forEach((term) => {
        let remainingTerms =
          moment(term.startDate).diff(termStart) /
          60000 /
          values.package.duration;
        remainingTerms = parseInt(remainingTerms);
        if (remainingTerms > 0) {
          for (let i = 0; i < remainingTerms; i++) {
            remainingTime -= values.package.duration;
            let termEnd = moment(termStart);
            termEnd.add(values.package.duration, "m");
            terms.push({
              startDate: termStart.toDate(),
              endDate: termEnd.toDate(),
              scheduled: false,
            });
            termStart = termEnd;
          }
        }
        termStart = moment(term.endDate);
      });
      let remainingTerms = remainingTime / values.package.duration;
      remainingTerms = parseInt(remainingTerms);
      if (remainingTerms > 0) {
        for (let i = 0; i < remainingTerms; i++) {
          remainingTime -= values.package.duration;
          let termEnd = moment(termStart);
          termEnd.add(values.package.duration, "m");
          terms.push({
            startDate: termStart.toDate(),
            endDate: termEnd.toDate(),
            scheduled: false,
          });
          termStart = termEnd;
        }
      }
      let sortedArray = terms.sort(
        (date1, date2) => date1.startDate - date2.startDate
      );
      setTermsArr(sortedArray);
    }
  }, [totalRemainingTime]);

  const renderTerms = (terms) => {
    let termsArr = [];
    terms.map((term, i) => {
      termsArr.push(
        <Col xs={12} md={4} lg={3} key={i}>
          <Card
            className={classnames("card-block clickable", {
              disabled: term.scheduled,
              active: i === activeItem,
            })}
            onClick={() => {
              if (!term.scheduled) {
                handleItemClick(term, i);
                handleClick("term", term);
              }
            }}
          >
            <Row className="text-center mx-auto">
              <Col xs={12}>
                {moment(term.startDate).format("HH") +
                  ":" +
                  moment(term.startDate).format("mm") +
                  " - " +
                  moment(term.endDate).format("HH") +
                  ":" +
                  moment(term.endDate).format("mm")}
              </Col>
            </Row>
          </Card>
        </Col>
      );
    });
    return termsArr;
  };

  return (
    <Row>
      <Col xs={12} className="text-center mb-4">
        <p>Изберете датум и време за состанокот</p>
      </Col>
      {tomorrowsDate && (
        <Col xs={12}>
          <Calendar
            className="m-auto"
            minDate={new Date()}
            tileDisabled={(date) =>
              (date.view === "month" && date.date.getDay() === 0) ||
              (date.view === "month" && date.date.getDay() === 6) ||
              (todaysDate &&
                date.view === "month" &&
                date.date.getFullYear() === todaysDate.getFullYear() &&
                date.date.getMonth() === todaysDate.getMonth() &&
                date.date.getDate() === todaysDate.getDate())
            }
            defaultValue={tomorrowsDate}
            onChange={(date) => {
              setTermsArr([]);
              setSelectedDate(date);
            }}
          />
        </Col>
      )}

      {!termsFilteredLoading && termsFiltered && selectedDate ? (
        <Col xs={12}>
          <Row className="mt-4"> {renderTerms(termsArr)} </Row>
        </Col>
      ) : (
        <Col xs={12}>
          <Loader />
        </Col>
      )}
    </Row>
  );
};

export default Step4;
