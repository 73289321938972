import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get filtered subscribers
export const subscribersFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_FILTERED_SUBSCRIBERS_START,
  };
};
export const subscribersFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_SUBSCRIBERS_SUCCESS,
    subscribers: data,
  };
};
export const subscribersFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTERED_SUBSCRIBERS_COUNT_SUCCESS,
    count: data,
  };
};
export const subscribersFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERED_SUBSCRIBERS_FAIL,
    error: error,
  };
};
export const subscribersFilteredFetch = (token, skip, limit, filters) => {
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(subscribersFilteredFetchStart());
    fetch(`${API}/subscribers/filtered/count`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ filters: data.filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let subscribersCount = result.count;
        fetch(`${API}/subscribers/filtered`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(subscribersFilteredFetchFail(data.error));
            } else {
              dispatch(subscribersFilteredFetchSuccess(data));
              dispatch(subscribersFilteredCountFetchSuccess(subscribersCount));
            }
          })
          .catch((error) => {
            dispatch(subscribersFilteredFetchFail(error));
          });
      });
  };
};

//create subscriber
export const subscriberCreateStart = () => {
  return {
    type: actionTypes.CREATE_SUBSCRIBER_START,
  };
};
export const subscriberCreateSuccess = (data) => {
  return {
    type: actionTypes.CREATE_SUBSCRIBER_SUCCESS,
    subscriber: data,
  };
};
export const subscriberCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_SUBSCRIBER_FAIL,
    error: error,
  };
};
export const subscriberCreate = (subscriberData) => {
  return (dispatch) => {
    dispatch(subscriberCreateStart());
    fetch(`${API}/subscriber/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(subscriberData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(subscriberCreateFail(data.error));
        } else {
          dispatch(subscriberCreateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(subscriberCreateFail(error));
      });
  };
};

//delete subscriber
export const subscriberDeleteStart = () => {
  return {
    type: actionTypes.DELETE_SUBSCRIBER_START,
  };
};
export const subscriberDeleteSuccess = (data) => {
  return {
    type: actionTypes.DELETE_SUBSCRIBER_SUCCESS,
    subscriber: data,
  };
};
export const subscriberDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_SUBSCRIBER_FAIL,
    error: error,
  };
};
export const subscriberDelete = (subscriberId, userId, token) => {
  return (dispatch) => {
    dispatch(subscriberDeleteStart());
    fetch(`${API}/subscriber/${subscriberId}/${userId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(subscriberDeleteFail(data.error));
        } else {
          dispatch(subscriberDeleteSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(subscriberDeleteFail(error));
      });
  };
};

//clear subscriber message
export const subscriberMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_SUBSCRIBER_MESSAGE_SUCCESS,
  };
};
export const subscriberMessageClear = () => {
  return (dispatch) => {
    dispatch(subscriberMessageClearSuccess());
  };
};
