import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  order: null,
  orderSuccess: null,
  orderError: null,
  orderLoading: false,

  orders: null,
  ordersSuccess: null,
  ordersError: null,
  ordersLoading: false,

  ordersWeekly: null,
  ordersWeeklyCount: null,
  ordersWeeklySuccess: null,
  ordersWeeklyError: null,
  ordersWeeklyLoading: false,

  ordersMonthly: null,
  ordersMonthlyCount: null,
  ordersMonthlySuccess: null,
  ordersMonthlyError: null,
  ordersMonthlyLoading: false,

  orderStatuses: null,
  orderStatusesSuccess: null,
  orderStatusesError: null,
  orderStatusesLoading: false,

  orderUpdateSuccess: null,
  orderUpdateError: null,
  orderUpdateLoading: false,
};

//get all orders
const ordersFetchStart = (state) => {
  return updateObject(state, {
    ordersSuccess: null,
    ordersError: null,
    ordersLoading: true,
  });
};
const ordersFetchSuccess = (state, action) => {
  return updateObject(state, {
    orders: action.orders,
    ordersSuccess: true,
    ordersError: null,
    ordersLoading: false,
  });
};
const ordersFetchFail = (state, action) => {
  return updateObject(state, {
    ordersError: action.error,
    ordersSuccess: false,
    ordersLoading: false,
  });
};

//get order by ID
const orderFetchStart = (state) => {
  return updateObject(state, {
    orderSuccess: null,
    orderError: null,
    orderLoading: true,
  });
};
const orderFetchSuccess = (state, action) => {
  return updateObject(state, {
    order: action.order,
    orderSuccess: true,
    orderError: null,
    orderLoading: false,
  });
};
const orderFetchFail = (state, action) => {
  return updateObject(state, {
    orderError: action.error,
    orderSuccess: false,
    orderLoading: false,
  });
};

//get weekly orders
const ordersWeeklyFetchStart = (state) => {
  return updateObject(state, {
    ordersWeeklySuccess: null,
    ordersWeeklyError: null,
    ordersWeeklyLoading: true,
  });
};
const ordersWeeklyFetchSuccess = (state, action) => {
  return updateObject(state, {
    ordersWeekly: action.ordersWeekly,
    ordersWeeklySuccess: true,
    ordersWeeklyError: null,
    ordersWeeklyLoading: false,
  });
};
const ordersWeeklyCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    ordersWeeklyCount: action.count,
  });
};
const ordersWeeklyFetchFail = (state, action) => {
  return updateObject(state, {
    ordersWeeklyError: action.error,
    ordersWeeklySuccess: false,
    ordersWeeklyLoading: false,
  });
};

//get monthly orders
const ordersMonthlyFetchStart = (state) => {
  return updateObject(state, {
    ordersMonthlySuccess: null,
    ordersMonthlyError: null,
    ordersMonthlyLoading: true,
  });
};
const ordersMonthlyFetchSuccess = (state, action) => {
  return updateObject(state, {
    ordersMonthly: action.ordersMonthly,
    ordersMonthlySuccess: true,
    ordersMonthlyError: null,
    ordersMonthlyLoading: false,
  });
};
const ordersMonthlyCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    ordersMonthlyCount: action.count,
  });
};
const ordersMonthlyFetchFail = (state, action) => {
  return updateObject(state, {
    ordersMonthlyError: action.error,
    ordersMonthlySuccess: false,
    ordersMonthlyLoading: false,
  });
};

//create order
const orderCreateStart = (state) => {
  return updateObject(state, {
    orderSuccess: null,
    orderError: null,
    orderLoading: true,
  });
};
const orderCreateSuccess = (state) => {
  return updateObject(state, {
    orderSuccess: true,
    orderError: null,
    orderLoading: false,
  });
};
const orderCreateFail = (state, action) => {
  return updateObject(state, {
    orderError: action.error,
    orderSuccess: false,
    orderLoading: false,
  });
};

//get status values
const orderStatusesFetchStart = (state) => {
  return updateObject(state, {
    orderStatusesSuccess: null,
    orderStatusesError: null,
    orderStatusesLoading: true,
  });
};
const orderStatusesFetchSuccess = (state, action) => {
  return updateObject(state, {
    orderStatuses: action.statuses,
    orderStatusesSuccess: true,
    orderStatusesError: null,
    orderStatusesLoading: false,
  });
};
const orderStatusesFetchFail = (state, action) => {
  return updateObject(state, {
    orderStatusesError: action.error,
    orderStatusesSuccess: false,
    orderStatusesLoading: false,
  });
};

//update order status
const orderStatusUpdateStart = (state) => {
  return updateObject(state, {
    orderUpdateSuccess: null,
    orderUpdateError: null,
    orderUpdateLoading: true,
  });
};
const orderStatusUpdateSuccess = (state, action) => {
  return updateObject(state, {
    order: action.order,
    orderUpdateSuccess: true,
    orderUpdateError: null,
    orderUpdateLoading: false,
  });
};
const orderStatusUpdateFail = (state, action) => {
  return updateObject(state, {
    orderUpdateError: action.error,
    orderUpdateSuccess: false,
    orderUpdateLoading: false,
  });
};

//clear order message
const orderMessageClearSuccess = (state) => {
  return updateObject(state, {
    orderSuccess: null,
    orderError: null,
    ordersSuccess: null,
    ordersError: null,
    ordersWeeklySuccess: null,
    ordersWeeklyError: null,
    ordersMonthlySuccess: null,
    ordersMonthlyError: null,
    orderStatusesSuccess: null,
    orderStatusesError: null,
    orderUpdateSuccess: null,
    orderUpdateError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_START:
      return orderFetchStart(state);
    case actionTypes.FETCH_ORDER_SUCCESS:
      return orderFetchSuccess(state, action);
    case actionTypes.FETCH_ORDER_FAIL:
      return orderFetchFail(state, action);

    case actionTypes.FETCH_ORDERS_START:
      return ordersFetchStart(state);
    case actionTypes.FETCH_ORDERS_SUCCESS:
      return ordersFetchSuccess(state, action);
    case actionTypes.FETCH_ORDERS_FAIL:
      return ordersFetchFail(state, action);

    case actionTypes.FETCH_WEEKLY_ORDERS_START:
      return ordersWeeklyFetchStart(state);
    case actionTypes.FETCH_WEEKLY_ORDERS_SUCCESS:
      return ordersWeeklyFetchSuccess(state, action);
    case actionTypes.FETCH_WEEKLY_ORDERS_COUNT_SUCCESS:
      return ordersWeeklyCountFetchSuccess(state, action);
    case actionTypes.FETCH_WEEKLY_ORDERS_FAIL:
      return ordersWeeklyFetchFail(state, action);

    case actionTypes.FETCH_MONTHLY_ORDERS_START:
      return ordersMonthlyFetchStart(state);
    case actionTypes.FETCH_MONTHLY_ORDERS_SUCCESS:
      return ordersMonthlyFetchSuccess(state, action);
    case actionTypes.FETCH_MONTHLY_ORDERS_COUNT_SUCCESS:
      return ordersMonthlyCountFetchSuccess(state, action);
    case actionTypes.FETCH_MONTHLY_ORDERS_FAIL:
      return ordersMonthlyFetchFail(state, action);

    case actionTypes.CREATE_ORDER_START:
      return orderCreateStart(state);
    case actionTypes.CREATE_ORDER_SUCCESS:
      return orderCreateSuccess(state);
    case actionTypes.CREATE_ORDER_FAIL:
      return orderCreateFail(state, action);

    case actionTypes.FETCH_ORDER_STATUSES_START:
      return orderStatusesFetchStart(state);
    case actionTypes.FETCH_ORDER_STATUSES_SUCCESS:
      return orderStatusesFetchSuccess(state, action);
    case actionTypes.FETCH_ORDER_STATUSES_FAIL:
      return orderStatusesFetchFail(state, action);

    case actionTypes.UPDATE_ORDER_STATUS_START:
      return orderStatusUpdateStart(state);
    case actionTypes.UPDATE_ORDER_STATUS_SUCCESS:
      return orderStatusUpdateSuccess(state, action);
    case actionTypes.UPDATE_ORDER_STATUS_FAIL:
      return orderStatusUpdateFail(state, action);

    case actionTypes.CLEAR_ORDER_MESSAGE_SUCCESS:
      return orderMessageClearSuccess(state);
    default:
      return state;
  }
};
export default reducer;
